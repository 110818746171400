import React, { useEffect, useState } from "react";

//redux
import { connect, useSelector } from "react-redux";

//action
import { checkUsername, topupWallet } from "../store/wallet/action";

//routing
import { Link } from "react-router-dom";

import { permissionError } from "../util/Alert";

//MUI
import { RadioGroup, FormLabel, FormControl, FormControlLabel, Radio } from "@material-ui/core";
import { inputNumberValidation } from "../util/Helper";

const walletTypes = [
    { id: "rCoin", name: "rCoin" },
    { id: "diamond", name: "Diamond" },
];

const type = [
    { id: "c", name: "Credit" },
    { id: "d", name: "Debit" },
];

const Wallet = (props) => {
    const [username, setUsername] = useState("");
    const [selectedWallet, setSelectedWallet] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [amount, setAmount] = useState("");
    const [remarks, setRemarks] = useState("");
    const [errors, setErrors] = useState({});
    const [cancelFn, setCancelFn] = useState(() => () => {});

    const hasPermission = useSelector((state) => state.admin.admin.flag);

    const wallet = useSelector((state) => state.wallet.wallet);

    useEffect(() => {
        if (wallet) {
            if (wallet.usernameError) {
                setErrors({ ...errors, username: wallet.usernameError });
            } else {
                setErrors({ ...errors, username: "" });
                if (wallet.user && wallet.status) {
                    setUsername("");
                    setAmount("");
                    setRemarks("");
                    setSelectedWallet("");
                    setSelectedType("");
                }
            }
        }
    }, [wallet]);

    // Cancel previous api call.
    useEffect(() => () => cancelFn(), [cancelFn]);

    const handleUsernameCheck = (val) => {
        let canceled = false;
        let timer;
        const newCancelFn = () => {
            canceled = true;
            clearTimeout(timer);
        };

        setUsername(val);

        if (val !== "") {
            timer = setTimeout(() => {
                props.checkUsername({ username: val });
                if (canceled) {
                    return;
                }
            }, 800);
        }

        setCancelFn(() => newCancelFn);
    };

    const handleTopUp = (e) => {
        e.preventDefault();
        const error = {};
        let validAmount = inputNumberValidation(amount);


        if (!username) error.username = "Username is required!";
        if (!amount) error.amount = "Amount is required!";
        if (!remarks) error.remarks = "Remarks is required!";
  

        if (!selectedType || !selectedWallet || !validAmount) {
            if (!selectedWallet) error.wallet = "Wallet is Required!";
            if (!selectedType) error.type = "Type is Required!";
            if (!validAmount) error.amount = "Amount should be number";

            return setErrors({ ...error });
        } else {
            setErrors({ ...errors, wallet: "", type: "", amount: "" });
        }

        if (!hasPermission) return permissionError();

        const data = {
            username,
            type: selectedType,
            wallet: selectedWallet,
            amount,
            remarks,
        };

        props.topupWallet(data);
    };

    return (
        <>
            <div className="page-heading">
                <div className="page-title">
                    <div className="row">
                        <div className="col-12 col-md-6 order-md-1 order-last">
                            <h3 className="mb-3">Wallet</h3>
                        </div>
                        <div className="col-12 col-md-6 order-md-2 order-first">
                            <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin/dashboard" className="text-danger">
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Wallet
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

                <section id="basic-vertical-layouts">
                    <div className="row match-height">
                        <div className="col-md-12 col-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form form-vertical">
                                            <div className="form-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Username</label>
                                                            <input
                                                                className="form-control"
                                                                name="username"
                                                                value={username}
                                                                onChange={(e) => {
                                                                    if (!e.target.value) {
                                                                        return setErrors({
                                                                            ...errors,
                                                                            username: "Username is Required!",
                                                                        });
                                                                    } else {
                                                                        handleUsernameCheck(e.target.value);
                                                                        return setErrors({
                                                                            ...errors,
                                                                            username: "",
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="ml-2 mb-3 mt-2">
                                                        {errors.username && (
                                                            <div className="pl-1 text-left pb-1">
                                                                <span className="text-red">{errors.username}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-12">
                                                        <FormControl>
                                                            <label for="wallet-label">Wallet</label>
                                                            <RadioGroup value={selectedWallet} row name="row-radio-buttons-group">
                                                                {walletTypes.map((item) => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            key={item.id}
                                                                            value={item.id}
                                                                            onChange={(e) => setSelectedWallet(e.target.value)}
                                                                            control={<Radio />}
                                                                            label={item.name}
                                                                        />
                                                                    );
                                                                })}
                                                            </RadioGroup>
                                                            <div className="ml-2 mb-3 mt-2">
                                                                {errors.wallet && (
                                                                    <div className="pl-1 text-left pb-1">
                                                                        <span className="text-red">{errors.wallet}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-12">
                                                        <FormControl>
                                                            <label for="type-label">Type</label>
                                                            <RadioGroup value={selectedType} row name="row-radio-buttons-group">
                                                                {type.map((item) => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            key={item.id}
                                                                            value={item.id}
                                                                            onChange={(e) => setSelectedType(e.target.value)}
                                                                            control={<Radio />}
                                                                            label={item.name}
                                                                        />
                                                                    );
                                                                })}
                                                            </RadioGroup>
                                                            <div className="ml-2 mb-3 mt-2">
                                                                {errors.type && (
                                                                    <div className="pl-1 text-left pb-1">
                                                                        <span className="text-red">{errors.type}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Amount</label>
                                                            <input
                                                                className="form-control"
                                                                name="amount"
                                                                value={amount}
                                                                onChange={(e) => {
                                                                    setAmount(e.target.value);
                                                                    if (!e.target.value) {
                                                                        return setErrors({
                                                                            ...errors,
                                                                            amount: "Amount is Required!",
                                                                        });
                                                                    } else {
                                                                        return setErrors({
                                                                            ...errors,
                                                                            amount: "",
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ml-2 mb-3 mt-2">
                                                        {errors.amount && (
                                                            <div className="pl-1 text-left pb-1">
                                                                <span className="text-red">{errors.amount}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Remarks</label>
                                                            <input
                                                                className="form-control"
                                                                name="remarks"
                                                                value={remarks}
                                                                onChange={(e) => {
                                                                    setRemarks(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ml-2 mb-3 mt-2">
                                                        {errors.remarks && (
                                                            <div className="pl-1 text-left pb-1">
                                                                <span className="text-red">{errors.remarks}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-4 d-flex justify-content-end">
                                                    <button type="button" className="btn btn-danger me-1 mb-1" onClick={handleTopUp}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default connect(null, { checkUsername, topupWallet })(Wallet);
