import axios from "axios";
import { Toast } from "../../util/Toast";
import { formatDateTime } from "../../util/Helper";
import { GET_MULTI_LIVE_ROOM, GET_LIVE_ROOM_DETAIL } from "./types";
import dayjs from "dayjs";

  export const getMultiLiveLiveRoomByLiveRoomId =
  (liveRoomId, start,limit) => (dispatch) => {
    let url = `live-room/${liveRoomId}/multi-live-room?start=${start}&limit=${limit}&sortType=-1&sortBy=`

    axios.get(url)
      .then((res) => {
        if (res.status) {
        
          dispatch({
            type: GET_MULTI_LIVE_ROOM,
            payload: res.data?.data,
            total:res.data?.total,
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

  export const getLiveRoomDetail =
  (liveRoomId) => (dispatch) => {
    let url = `live-room/${liveRoomId}`

    axios.get(url)
      .then((res) => {
        if (res.status) {
        
          dispatch({
            type: GET_LIVE_ROOM_DETAIL,
            payload: res.data?.data,
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };