import dayjs from "dayjs";
import { GET_MULTI_LIVE_ROOM, GET_LIVE_ROOM_DETAIL } from "./types"
import { exportData } from "../../util/Helper";

const initialState = {
  multiLiveRoom: [],
  totalMultiLiveRoom: 0,
  liveRoom: [],
};

const multiLiveRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MULTI_LIVE_ROOM:
      return {
        ...state,
        multiLiveRoom: action.payload,
        totalMultiLiveRoom: action.total,
      };
    case GET_LIVE_ROOM_DETAIL:
      return {
        ...state,
        liveRoom: action.payload,
      }
    default:
      return state;
  }
};

export default multiLiveRoomReducer;
