import { exportData } from "../../util/Helper";
import { CHECK_USERNAME, WALLET_TOPUP, GET_REPORT_HISTORY, EXPORT_REPORT } from "./types";
import dayjs from "dayjs";

const initialState = {
    wallet: {},
    report:[],
    totalReport: 0,
    income: 0,
    outgoing: 0,
    balanceIncome: 0,
    liveStreamingIncome: 0,
};

const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_USERNAME:
            return {
                wallet: action.payload,
            };
        case WALLET_TOPUP:
            return {
                wallet: { ...state.wallet, ...action.payload },
            };
        case GET_REPORT_HISTORY:
            return{
                ...state,
                report: action.payload.history,
                totalReport: action.payload.total,
                income: action.payload.incomeTotal,
                outgoing: action.payload.outgoingTotal,
                balanceIncome: action.payload.balanceTotal,
                liveStreamingIncome: action.payload.income,
              };
        case EXPORT_REPORT:
            const today = new Date();
            let date = dayjs(today).format("DDMMYY-HHmmss");
            const csvData = action.payload.csv
            exportData(csvData, (action.payload.type??"celelive")+'-report-'+date+'.csv', 'text/csv;charset=utf-8;');
        default:
            return state;
    }
};

export default walletReducer;
