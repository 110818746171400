import React, { useEffect, useState } from "react";

//react-redux
import { useDispatch, useSelector, connect } from "react-redux";

//routing
import { Link } from "react-router-dom";

//action
import { getRedeem } from "../../store/redeem/action";
import { getSetting } from "../../store/setting/action";

//MUI
import { TablePagination } from "@material-ui/core";
// type
import { OPEN_ACCEPT_DIALOG, OPEN_DECLINE_DIALOG } from "../../store/redeem/types";
// dialog
import RedeemAcceptDialog from "../dialog/Redeem/Accept";
import RedeemDeclineDialog from "../dialog/Redeem/Decline";
//dayjs
import dayjs from "dayjs";

import arraySort from "array-sort";
import { convertThousandNumbers } from "../../util/Helper";

const TablePaginationActions = React.lazy(() => import("./TablePagination"));

const PendingRedeemTable = (props) => {
  const dispatch = useDispatch();
  const [coinSort, setCoinSort] = useState(true);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    props.getSetting();
    props.getRedeem("pending"); // eslint-disable-next-line
  }, []);

  const redeem = useSelector((state) => state.redeem.redeem);
  const setting = useSelector((state) => state.setting.setting);

  useEffect(() => {
    setData(redeem);
  }, [redeem]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase()
      ? e.target.value.trim().toUpperCase()
      : e.target.value.trim();
    if (value) {
      const data = redeem.filter((data) => {
        return (
          data?.userId?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.paymentGateway?.toUpperCase()?.indexOf(value) > -1 ||
          data?.description?.toUpperCase()?.indexOf(value) > -1 ||
          data?.rCoin?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(redeem);
    }
  };

  const handleOpen = (data, type) => {
    if(type == 'accept'){
      dispatch({ type: OPEN_ACCEPT_DIALOG, redeem: data });
    }else{
      dispatch({ type: OPEN_DECLINE_DIALOG, redeem: data });
    }
  };

  const handleCoinSort = () => {
    setCoinSort(!coinSort);
    arraySort(data, "rCoin", { reverse: coinSort });
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3">Pending Redeem Request</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Redeem
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Pending Redeem
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left"></div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={handleSearch}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>User</th>
                    <th>Payment Gateway</th>
                    <th>Description</th>
                    <th>Rate (rCoin = SGD)</th>
                    <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                      RCoin {coinSort ? " ▼" : " ▲"}
                    </th>
                    <th>
                      {setting.currency === "£"
                        ? "GBP(£)"
                        : setting.currency === "$"
                        ? "SGD($)"
                        : "Rupee(₹)"}
                    </th>
                    <th>Fee Percent</th>
                    <th>Fee</th>
                    <th>Total</th>
                    <th>CreatedAt</th>
                    <th>Accept</th>
                    <th>Decline</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    (rowsPerPage > 0
                      ? data.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : data
                    ).map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.userId?.name}</td>
                          <td>{data.paymentGateway}</td>
                          <td>{data.description}</td>
                          <td>{convertThousandNumbers(data.rCoinForCashOut)} = {convertThousandNumbers(data.dollarToRCoin ?? 1)}</td>
                          <td>{convertThousandNumbers(data.rCoin)}</td>
                          <td>{convertThousandNumbers(data.dollar)}</td>
                          <td>{convertThousandNumbers(data.feePercent)}</td>
                          <td>{convertThousandNumbers(data.fee)}</td>
                          <td>{convertThousandNumbers(data.total)}</td>
                          <td>{dayjs(data.createdAt).format("DD MMM, YYYY")}</td>
                          <td>
                            <button
                              class="btn btn-sm text-white success"
                              onClick={() =>
                                handleOpen(data, 'accept')
                              }
                            >
                              <i class="fa fa-check"></i> Accept
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-sm text-white danger"
                              onClick={() =>
                                handleOpen(data, 'decline')
                              }
                            >
                              <i class="fas fa-times"></i> Decline
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="13" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <TablePagination
                id="pagination"
                component="div"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  100,
                  { label: "All", value: -1 },
                ]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          </div>
        </div>
      </div>
      <RedeemAcceptDialog/>
      <RedeemDeclineDialog/>
    </>
  );
};

export default connect(null, { getRedeem, getSetting })(
  PendingRedeemTable
);
