export const GET_USER = "GET_USER";
export const AGENT_SWITCH = "AGENT_SWITCH";
export const FEATURED_SWITCH = "FEATURED_SWITCH";
export const BLOCK_UNBLOCK_SWITCH = "BLOCK_UNBLOCK_SWITCH";
export const STREAMER_SWITCH = "STREAMER_SWITCH";
export const MODERATOR_SWITCH = "MODERATOR_SWITCH";

export const GET_HISTORY = "GET_HISTORY";

export const EDIT_COIN = "EDIT_COIN";
export const UPDATE_USER = "UPDATE_USER";

