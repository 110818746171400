import React, { useEffect, useRef, useState } from "react";

//dayjs
import dayjs from "dayjs";

//jquery
import $ from "jquery";

//redux
import { connect, useSelector } from "react-redux";

//action
import { getStreamerLiveSummary, getLiveSummaryDetails, exportLiveSummaryReport } from "../../store/streamerLiveSummary/action";

//routing
import { Link, useHistory } from "react-router-dom";


//image
import Male from "../../assets/images/male.png";


//pagination
import Pagination from "../../pages/Pagination";

//Date Range Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { convertThousandNumbers, formatDateTime } from "../../util/Helper";
import { Tooltip, makeStyles } from "@material-ui/core";
import LiveSummaryDetailDialog from "../dialog/LiveSummaryDetail";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "0 !important"
    },
    removeElement: {
        display: "none !important"
    },
    header: {
        paddingBottom: "8px",
        background: "white !important"
    },
    month: {
        padding: "8px 0"
    },
    selectedMonth: {
        background: "gray !important",
        padding: "8px 0"
    },
    inputBox: {
        // background: 'white !important',
        border: "none"
    }
}));


const LiveSummaryTable = (props) => {
    const ref = useRef();
    const classes = useStyles();
    const history_ = useHistory();

    const [data, setData] = useState([]);
    const searchParams = new URLSearchParams(window.location.search);

    const [activePage, setActivePage] = useState(searchParams.get("page")??1);
    const [search, setSearch] = useState(searchParams.get('search')??"ALL");

    const [rowsPerPage, setRowsPerPage] = useState(searchParams.get('rows')??15);

    const [cancelFn, setCancelFn] = useState(() => () => { });

    // const [sDate, setsDate] = useState(searchParams.get('startDate')??dayjs().startOf("month").toDate());
    const [sDate, setsDate] = useState(searchParams.get('startDate') ? new Date(searchParams.get('startDate')) : dayjs().startOf("month").toDate());
    const startIndex = (activePage - 1) * rowsPerPage;
    
    // Cancel previous typing.
    useEffect(() => () => cancelFn(), [cancelFn]);

    const { streamer, totalStreamer, additionalInfo } = useSelector(
        (state) => state.streamerLiveSummary
    );

    useEffect(() => {
        window.addEventListener('click', function (e) {
            if (!document.getElementById('datePicker')?.contains(e.target)) {
                $("#datePicker").removeClass("show");
            }
        });
    }, []);

    useEffect(() => {
        let queryString = "?page=" + activePage
        if (search !== "ALL" && search) queryString += "&search=" + search
        if (rowsPerPage) queryString += `&rows=${rowsPerPage}`;
        if (sDate) queryString += `&startDate=${sDate.toISOString()}`;
        window.history.pushState("", "", window.location.pathname + queryString);
        props.getStreamerLiveSummary(activePage, rowsPerPage, search, sDate, dayjs(sDate).endOf("month").toDate())

    }, [activePage, rowsPerPage, search, sDate]);


    useEffect(() => {
        setData(streamer);
    }, [streamer]); 


    const handleSearchInput = (event) => {
        event.preventDefault()
        let canceled = false;
        let timer;
        const newCancelFn = () => {
            canceled = true;
            clearTimeout(timer);
        };

        const query = event.target.value;

        if (query) {
            timer = setTimeout(() => {
                setSearch(query);
                setActivePage(1);
            }, 600);
        } else setSearch("ALL");

        setCancelFn(() => newCancelFn);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        handlePageChange(1);
        setRowsPerPage(value);
    };

    const viewDetail = (id) => {
        props.getLiveSummaryDetails(id, dayjs(sDate).format("YYYY-MM-DD"));
    }

    const exportThis = () => {
        props.exportLiveSummaryReport(activePage, rowsPerPage, search, formatDateTime(sDate), dayjs(sDate).endOf("month").toDate());
    }

    const handleUserInfo = (user) => {
        history_.push("/admin/user/detail/"+ user._id);
    };

    return (
        <>
            <LiveSummaryDetailDialog />
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className="mb-3">Streamer Live Summary</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Streamer Live Summary
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <div className="card stats-card">
                        <div className="card-body">
                            <div className="stats-info">
                                <h5 className="card-title">{convertThousandNumbers(additionalInfo.totalStreamerCompleteCount)}</h5>
                                <p className="stats-text">Overall Completed Target</p>
                            </div>
                            <div className="stats-icon change-success">
                                <i className="material-icons">people</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card stats-card">
                        <div className="card-body">
                            <div className="stats-info">
                                <h5 className="card-title">{"$" + convertThousandNumbers(additionalInfo.totalStreamerAmount)}</h5>
                                <p className="stats-text">Total Amount</p>
                            </div>
                            <div className="stats-icon change-danger">
                                <i className="material-icons">payments</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card stats-card">
                        <div className="card-body">
                            <div className="stats-info">
                                <h5 className="card-title">{convertThousandNumbers(additionalInfo.totalStreamerRCoin)}</h5>
                                <p className="stats-text">Total RCoin</p>
                            </div>
                            <div className="stats-icon change-pink">
                                <i className="material-icons">monetization_on</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card" id="card">
                        <div className="card-header pb-0">
                            <div className="row my-3">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6  d-flex align-items-center">
                                    <div className="input-container d-flex align-items-center">
                                        <label htmlFor="start" className="px-3 d-inline" style={{ whiteSpace: "pre" }}>Selected month:</label>
                                        <DatePicker
                                            className="form-control c-pointer"
                                            selected={sDate}
                                            onChange={(date) => setsDate(date)}
                                            dateFormat="MMM y"
                                            minDate={new Date("2023-01")}
                                            maxDate={dayjs().startOf("month").toDate()}
                                            showMonthYearPicker
                                            onFocus={e => e.target.blur()}
                                            ref={ref}
                                            onYearChange={() => {
                                                const selectedMonth = document.getElementsByClassName(
                                                    "react-datepicker__month-text--keyboard-selected"
                                                );
                                                selectedMonth[0].classList.add(classes.selectedMonth);
                                            }}
                                            onCalendarOpen={() => {
                                                const x = document.getElementsByClassName(
                                                    "react-datepicker-popper"
                                                );
                                                const y = document.getElementsByClassName(
                                                    "react-datepicker__triangle"
                                                );
                                                const z = document.getElementsByClassName(
                                                    "react-datepicker__header"
                                                );
                                                const months = document.getElementsByClassName(
                                                    "react-datepicker__month-text"
                                                );
                                                const selectedMonth = document.getElementsByClassName(
                                                    "react-datepicker__month-text--keyboard-selected"
                                                );
                                                x[0].classList.add(classes.root);
                                                y[0].classList.add(classes.removeElement);
                                                z[0].classList.add(classes.header);
                                                for (let i = 0; i < months.length; i++) {
                                                    months[i].classList.add(classes.month);
                                                }
                                                selectedMonth[0].classList.add(classes.selectedMonth);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 float-right">
                                    <div className="d-flex align-items-center">
                                        <div className="input-group border rounded-pill me-4">
                                            <div className="input-group-prepend border-0">
                                                <div id="button-addon4" className="btn text-danger">
                                                    <i className="fas fa-search mt-2"></i>
                                                </div>
                                            </div>
                                            <input
                                                type="search"
                                                id="searchBar"
                                                defaultValue={search != 'ALL' ? search : ""}
                                                autoComplete="off"
                                                placeholder="Search by name or username"
                                                aria-describedby="button-addon4"
                                                className="form-control bg-none border-0 rounded-pill searchBar"
                                                onChange={(e) => {
                                                    handleSearchInput(e)
                                                }}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => exportThis()}
                                        >Export
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body card-overflow pt-0">
                            <table className="table table-striped mt-5">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Image</th>
                                        <th>Name (Username)</th>
                                        <th>Agency</th>
                                        <th>Stream Time<br />(minutes)</th>
                                        <th>Total RCoin</th>
                                        <th>Total Amount</th>
                                        <th>Agency Fee</th>
                                        <th>Total Payout</th>
                                        <th>Daily Task</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? (
                                        data.map((data, index) => {
                                            return (
                                                <tr key={startIndex+index}>
                                                    <td>{startIndex+index + 1}</td>
                                                    <td>
                                                        <img
                                                            height="50px"
                                                            width="50px"
                                                            alt="app"
                                                            src={data.image ? data.image : Male}
                                                            onClick={() => handleUserInfo(data)}
                                                            className="pointer-cursor"
                                                            style={{
                                                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                                border: "2px solid #fff",
                                                                borderRadius: 10,
                                                                float: "left",
                                                                objectFit: "cover",
                                                            }}
                                                        />
                                                    </td>
                                                    <td
                                                     onClick={() => handleUserInfo(data)}
                                                     className="pointer-cursor"
                                                     >{data.name + (data.username ? " (" + data.username + ")" : "")}</td>
                                                    <td>{data.agency ? data.agency.name : "-"}</td>
                                                    <td>
                                                    {
                                                        data.liveRoomInfo ?
                                                        dayjs().startOf('day').add(data.liveRoomInfo?.totalDuration, 'second').format('HH:mm:ss') :
                                                        '00:00:00'
                                                    }
                                                    </td>
                                                    <td>
                                                    {
                                                        data.liveRoomInfo ? 
                                                        convertThousandNumbers(data.liveRoomInfo?.totalRCoin ?? 0) : 0
                                                    }
                                                    </td>
                                                    <td>
                                                    {
                                                        data.liveRoomInfo ? 
                                                        "$" + convertThousandNumbers(data.liveRoomInfo?.totalAmount ?? 0) : "$" + 0
                                                    }
                                                    </td>
                                                    <td>
                                                    {
                                                        data.liveRoomInfo ? 
                                                        "$" + convertThousandNumbers(data.liveRoomInfo?.agencyFee ?? 0) : "$" + 0
                                                    }
                                                    </td>
                                                    <td>
                                                    {
                                                        data.liveRoomInfo ? 
                                                        "$" + convertThousandNumbers(data.liveRoomInfo?.totalPay ?? 0) : "$" + 0
                                                    }
                                                    </td>
                                                    <td><span className={data.liveRoomInfo?.isOverallComplete ? "text-success" : "text-red"}>
                                                        {data.liveRoomInfo?.dailyCompleteCount ?? 0}</span>
                                                        {"/" + data.streamerTaskSetting.minuteTask.minimumRequirement}</td>
                                                    <td>
                                                        <Tooltip title="Info">
                                                            <button
                                                                type="button"
                                                                disabled={parseInt(data.liveRoomInfo?.totalDuration ?? 0) <= 0 ? true : false}
                                                                className="btn btn-sm btn-info"
                                                                onClick={() => viewDetail(data._id)}>
                                                                <i className="fas fa-info-circle fa-lg"></i>
                                                            </button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="10" align="center">
                                                Nothing to show!!
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <Pagination
                                activePage={parseInt(activePage)}
                                rowsPerPage={rowsPerPage}
                                userTotal={totalStreamer}
                                handleRowsPerPage={handleRowsPerPage}
                                handlePageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { getStreamerLiveSummary, getLiveSummaryDetails, exportLiveSummaryReport })(LiveSummaryTable);
