import React, { useState, useEffect } from "react";

//react-router
import { connect, useSelector  } from "react-redux";

import { Link, useHistory, useLocation } from "react-router-dom";

//action
import { getUserDetail } from "../store/user/action";
import { getPKSessions } from "../store/pkSection/action";
import { getMultiLiveLiveRoomByLiveRoomId } from "../store/liveRoom/action";
import { pkTimeList, pkResultList, pkTypeList } from "../util/Helper";
import TeamPK from "../component/table/TeamPK";

//dayjs
import dayjs from "dayjs";



//jquery
import $ from "jquery";

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";


//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file



const timeList = pkTimeList();
const resultList = pkResultList();
const typeList = pkTypeList();

const PKDetail = (props) => {
  const history_ = useHistory();

  const [start, setStart] = useState(1);
  const [limit, seteLimit] = useState(10);
  const [selectedRow, setSelectedRow] = useState(0);

  const [data, setData] = useState([]);
  const [user, setUser] = useState(null);
  const [dataMultiLive, setDataMultiLive] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  const { multiLiveRoomId,liveRoomId,liveRoomUserNamePre, userName, pkMode } = props.location.state;

  const location = useLocation();

  // let pkMode = null;
  let teamWin = null;

  const { pkLiveRoom, totalPKLiveRoom } = useSelector(
    (state) => state.pkLiveRoomSession
  );
  const { multiLiveRoom, totalMultiLiveRoom } = useSelector(
    (state) => state.multiLiveRoom
  );

  useEffect(() => {
    props.getPKSessions(multiLiveRoomId, start, limit)
    // props.getMultiLiveLiveRoomByLiveRoomId(liveRoomId, start, limit)
}, [multiLiveRoomId]);


  useEffect(() => {
    window.addEventListener('click', function (e) {
      if (!document.getElementById('datePicker')?.contains(e.target)) {
        $("#datePicker").removeClass("show");
      }
    });
  }, []);

  useEffect(() => {
    setData(pkLiveRoom);
    // setDataMultiLive(multiLiveRoom);

  }, [pkLiveRoom]);


  
  

const getPkTypeLabel = (mode) => {
  const pkType = typeList.find(item => item.value === mode);
  return pkType ? pkType.label : null;
}

const getPkTimeLabel = (pkType) => {
  const pkTime = timeList.find(item => item.value === pkType);
  return pkTime ? pkTime.label : null;
}

const getPkResultLabel = (winnerTeam) => {
  const pkResult = resultList.find(item => item.value === winnerTeam);
  return pkResult ? pkResult.label : null;
}

const handleUserInfo = (userId) => {
  history_.push("/admin/user/detail/"+ userId);
};

  return (
    <>

      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3">{userName}'s PK Detail</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/user" className="text-danger">
                    User
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/admin/user/history/${liveRoomUserNamePre}`} className="text-danger">
                    History
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/admin/liveRoom/${liveRoomUserNamePre}/${liveRoomId}`} className="text-danger">
                    Multi-Live
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    PK Detail
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
                <div class="card-body card-overflow pt-0">
                  <div className="d-flex justify-content-between mt-5">
                    <h4 className="text-primary">PK Result</h4>
                  </div>
                  <table class="table mt-3">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>PK Start at</th>
                        <th>PK End at</th>
                        <th>PK Type</th>
                        <th>PK Time</th>
                        <th>Winner Team</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        data.map((rowData, index) => {
                          return (
                            <React.Fragment key={index} >
                              <tr onClick={() => setSelectedRow(selectedRow === index ? null : index)}>
                                <td>{index + 1}</td>
                                <td>{dayjs(rowData.pkStartAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{dayjs(rowData.pkEndAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{getPkTypeLabel(pkMode)}</td>
                                <td>{getPkTimeLabel(rowData.pkType)}</td>
                                <td>{getPkResultLabel(rowData.winnerTeam)}</td>
                                
                                <td>
                                {selectedRow === index ? <ExpandLessIcon style={{ fontSize: '1.0rem' }}/> : <ExpandMoreIcon style={{ fontSize: '1.0rem' }} />}
                                </td>
                                
                              </tr>
                              {selectedRow === index && (
                              <>
                                {/* Render Team component for Team1 */}
                                <TeamPK teamData={rowData} winnerTeam={rowData.winnerTeam} teamNumber={"Team 1"} />
                                
                                {/* Render Team component for Team2 */}
                                <TeamPK teamData={rowData} winnerTeam={rowData.winnerTeam} teamNumber={"Team 2"}/>
                              </>
                            )}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="8" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getPKSessions, getMultiLiveLiveRoomByLiveRoomId  })(
  PKDetail
);
