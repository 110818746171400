import { DELETE_REPORT, GET_REPORTED_USER } from "./types";

const initialState = {
  reportedUser: [],
};

const ReportedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTED_USER:
      return {
        ...state,
        reportedUser: action.payload,
      };
    case DELETE_REPORT:
      let tempReport = state.reportedUser
      let updateIndex = tempReport.findIndex((item) => item._id._id === action.payload.reportedUserId)

      if(updateIndex!=-1){
        tempReport[updateIndex].report =  tempReport[updateIndex].report.filter((item) => item._id !== action.payload.reportId);
        tempReport[updateIndex].count =  tempReport[updateIndex].report.length
      }
      return {
        ...state,
        reportedUser:[...tempReport]
      };
    default:
      return state;
  }
};

export default ReportedUserReducer;
