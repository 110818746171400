import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_AGENCY_DIALOG } from "../../store/agency/types";

//action
import { createNewAgency, updateAgency } from "../../store/agency/action";

import { permissionError } from "../../util/Alert";

const AgencyDialog = (props) => {
  const dispatch = useDispatch();

  const { dialog: open, dialogData } = useSelector((state) => state.agency);

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const [mongoId, setMongoId] = useState("");
  const [agency, setAgency] = useState("");

  const [errors, setError] = useState({
    agency: "",
  });
  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData._id);
      setAgency(dialogData.name);
    }
  }, [dialogData]);

  useEffect(
    () => () => {
      setError({
        agency: "",
      });
      setMongoId("");
      setAgency("");
    },
    [open]
  );

  const closePopup = (reason) => {
    if (reason.type == "click")
    dispatch({ type: CLOSE_AGENCY_DIALOG });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!agency?.trim()) return setError({ ...errors, agency: "agency is Required!" });

    if (!hasPermission) return permissionError();

    if (mongoId) {
      props.updateAgency(mongoId, { name: agency });
    } else {
      props.createNewAgency({ name: agency });
    }
  };




  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        
        
        <DialogTitle id="responsive-dialog-title"><span className="text-danger font-weight-bold h4" > {dialogData?"Update Agency":"Add New Agency"} </span></DialogTitle>


        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="form-group">
                  <label className="mb-2 text-gray">Agency</label>
                  {
                    mongoId ?

                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Enter Agency"
                        value={agency}
                        onChange={(e) => {
                          setAgency(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              agency: "Agency is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              agency: "",
                            });
                          }
                        }}
                      /> :
                      <textarea
                        type="text"
                        rows={3}
                        cols={3}
                        className="form-control"
                        required=""
                        placeholder="Enter Agency"
                        value={agency}
                        onChange={(e) => {
                          setAgency(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              agency: "Agency is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              agency: "",
                            });
                          }
                        }}
                      />
                  }
                  {!mongoId && <><span className="text-danger"></span></>}
                  {errors.agency && (
                    <div className="ml-2 mt-1">
                      {errors.agency && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.agency}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="mt-5">
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};



export default connect(null, { createNewAgency, updateAgency })(AgencyDialog);