import React from "react";
import Male from "../../assets/images/male.png";
import { Link, useHistory, useLocation } from "react-router-dom";

const TeamPK = ({ teamData, winnerTeam, teamNumber }) => {
    const history_ = useHistory();
    const getTeamTitle = () => {
        if (teamNumber === "Team 1") {
            return {
                title: "Team 1",
                className: "text-primary",
                classNameResult: winnerTeam === 1 ? "result-text win-text" : (teamData.winType === 1 && winnerTeam === 2) ? "result-text lose-text" : ((teamData.winType === 2 || 3) && winnerTeam === 2) ? "result-text lose-text" : "result-text draw-text",
                resultText: winnerTeam === 1 ? "Win" : (teamData.winType === 1 && winnerTeam === 2) ? "Lose" : ((teamData.winType === 2 || 3) && winnerTeam === 2) ? "Leave" : "Draw"
            };
        } else if (teamNumber === "Team 2") {
            return {
                title: "Team 2",
                className: "text-danger",
                classNameResult: winnerTeam === 2 ? "result-text win-text" : (teamData.winType === 1 && winnerTeam === 1) ? "result-text lose-text" : ((teamData.winType === 2 || 3) && winnerTeam === 1) ? "result-text lose-text" : "result-text draw-text",
                resultText: winnerTeam === 2 ? "Win" : (teamData.winType === 1 && winnerTeam === 1) ? "Lose" : ((teamData.winType === 2 || 3) && winnerTeam === 1) ? "Leave" : "Draw"
            };
        }
        // Default case
        return {
            title: "Unknown",
            className: "",
            resultText: ""
        };
    };
  const handleUserInfo = (userId) => {
    history_.push("/admin/user/detail/"+ userId);
  };

  return (
    <tr className="custom-table">
      <td colSpan="7">
        <div className="pkTitleHead">
          <h3 className={getTeamTitle().className}>
            {teamNumber}
          </h3>
          <p className={getTeamTitle().classNameResult}>{getTeamTitle().resultText}</p>
        </div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>No</th>
              <th>Image</th>
              <th>Name (Username)</th>
              <th>Earned Points</th>
            </tr>
          </thead>
          <tbody>
            {teamData.pkSessionParticipantList
            .filter(participant => participant.team === (teamNumber === "Team 1" ? 1 : 2))
            .map((participant, index) => (
              <tr key={`${index}-${teamData.team}-${participant.userInfo._id}`}>
                <td>{index + 1}</td>
                <td onClick={() => handleUserInfo(participant.userInfo._id)} className="pointer-cursor">
                  <img
                    height="50px"
                    width="50px"
                    alt="app"
                    src={participant.userInfo.image ? participant.userInfo.image : Male}
                    style={{
                      boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                      border: "2px solid #fff",
                      borderRadius: 10,
                      float: "left",
                      objectFit: "cover",
                    }}
                  />
                </td>
                <td onClick={() => handleUserInfo(participant.userInfo._id)} className="pointer-cursor">{participant.userInfo.name}</td>
                <td>{participant.totalGiftPoint}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default TeamPK;
