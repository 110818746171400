import axios from "axios";
import { Toast } from "../../util/Toast";
import { GET_TOP_RECEIVER_TOTAL_RECEIVED, GET_TOP_SENDER_TOTAL_SENT } from "./types";



  export const getTopSender =
  (type,date,start,limit,country) => (dispatch) => {
    let url = `gift-record/top-sender?type=${type}&selectedDate=${date}&start=${start}&limit=${limit}`
    if (country) {
      url += `&country=${country}`;
    }
    axios.get(url)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_TOP_SENDER_TOTAL_SENT,
            payload: res.data?.data ?? [],
            total:res.data.total
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

  export const getTopReceiver =
  (type,date,start,limit,country) => (dispatch) => {
    let url = `gift-record/top-receiver?type=${type}&selectedDate=${date}&start=${start}&limit=${limit}`
    if (country) {
      url += `&country=${country}`;
    }
    axios.get(url)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_TOP_RECEIVER_TOTAL_RECEIVED,
            payload: res.data?.data ?? [],
            total:res.data.total
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };