import React from "react";

//pagination
import TablePagination from "react-js-pagination";

const perPageList = [5, 10, 15, 20, 50, 100];
const Pagination = (props) => {
  const handlePage = (page) => {
    props.handlePageChange(page);
  };
  const handleRowsPerPage = (value) => {
    props.handleRowsPerPage(value);
  };

  return (
    <>
      <div className="d-md-flex justify-content-between">
        <div className="d-flex">
          <span className="m-auto mx-0">Rows</span>
          <select
            // defaultValue={props.rowsPerPage}
            value={props.rowsPerPage}
            className="form-select form-control mr-3 ml-2 mb-2 mb-md-0 mb-lg-0 dropdown"
            style={{ marginLeft: 5 }}
            onChange={(e) => {
              handleRowsPerPage(e.target.value);
            }}
          >
            {perPageList.map((item, index) => {
              return (
                <option key={item} class="text-dark" value={item} selected={props.rowsPerPage}>
                  {item}
                </option>)
            })}
          </select>
        </div>
        <div className="align-middle">
          <TablePagination
            activePage={props.activePage}
            itemsCountPerPage={props.rowsPerPage}
            totalItemsCount={props.userTotal}
            pageRangeDisplayed={5}
            onChange={(page) => handlePage(page)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
    </>
  );
};

export default Pagination;
