import React, { useState, useEffect } from "react";

//react-router
import { connect, useSelector } from "react-redux";

import { Link, useHistory } from "react-router-dom";

//action
import { reportHistory, exportReport, purgeCoin } from "../store/wallet/action";
//dayjs
import dayjs from "dayjs";

//jquery
import $ from "jquery";

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//pagination
import Pagination from "./Pagination";
import { useDispatch } from 'react-redux';

//image
import ads from "../assets/images/ads.png";
import diamond from "../assets/images/diamond.png";
import gift from "../assets/images/ic_gift.png";
import moneybag from "../assets/images/moneybag.png";
import male from "../assets/images/male.png";
import videocall from "../assets/images/videocall.png";
import withdraw from "../assets/images/withdraw.png";
import { alert, permissionError, warning } from "../util/Alert";

const Report = (props) => {
  const history_ = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const [cancelFn, setCancelFn] = useState(() => () => { });
  const start = dayjs().subtract(30, 'days');
  const end = dayjs();
  const [date, setDate] = useState([
    {
      startDate: start.toDate(),
      endDate: end.toDate(),
      key: 'selection',
    },
  ]);
  const [sDate, setsDate] = useState(() => {
    const storedSDate = searchParams.get('sDate');
    return storedSDate ? new Date(storedSDate) : dayjs().subtract(30, 'days').toDate();
  });
  
  const [eDate, seteDate] = useState(() => {
    const storedEDate = searchParams.get('eDate');
    return storedEDate ? new Date(storedEDate) : dayjs().toDate();
  });

  const [historyType, setHistoryType] = useState(() => searchParams.get('historyType')??"rCoin");
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState(() => {
    const savedPage = searchParams.get('activePage');
    return savedPage ? parseInt(savedPage, 10) : 1;
  })

  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRows = searchParams.get('rowsPerPage');
    return savedRows ? parseInt(savedRows, 10) : 15;
  });

  const [data, setData] = useState([]);
  const [search, setSearch] = useState(() => searchParams.get('search')??"");
  const [isPurged, setIsPurged] = useState(() => searchParams.get('isPurged')??2);  // 0: Exclude Purged, 1: Purged Only, 2: Include Purged //------------------------------------------------------------
  const startIndex = (activePage - 1) * rowsPerPage;

  const {
    report: history,
    totalReport,
    income,
    outgoing,
    balanceIncome,
    liveStreamingIncome
  } = useSelector(
    (state) => state.wallet
  );

  useEffect(() => {
    window.addEventListener('click', function (e) {
      if (!document.getElementById('datePicker')?.contains(e.target)) {
        $("#datePicker").removeClass("show");
      }
    });
  }, []);

  // Cancel previous typing.
  useEffect(() => () => cancelFn(), [cancelFn]);

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  useEffect(() => {
    const data = {
      type: historyType,
      start: activePage,
      limit: rowsPerPage,
      startDate: sDate,
      endDate: eDate,
    };

    if (historyType === "rCoin") {
      if (isPurged != 2) {
        data.isPurged = isPurged ? true : false
      }
    }

    if (search) {
      data.search = search;
    }

    let queryString = "?activePage=" + activePage;
    sDate && (queryString += "&sDate=" + sDate);
    eDate && (queryString  += "&eDate=" + eDate);
    isPurged && (queryString  += "&isPurged=" + isPurged);
    historyType && (queryString  += "&historyType=" + historyType);
    rowsPerPage && (queryString  += "&rowsPerPage=" + rowsPerPage);
    search && (queryString  += "&search=" + search);
    window.history.pushState("", "", window.location.pathname + encodeURI(queryString));

    dispatch(reportHistory(data));
  }, [activePage, rowsPerPage, historyType, search, eDate, isPurged,dispatch]);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker").removeClass("show");
    setData(history);
  }, [history]);


  const exportThisReport = () => {
    const data = {
      type: historyType,
      startDate: sDate,
      endDate: eDate,
    };

    if (historyType === "rCoin") {
      if (isPurged != 2) {
        data.isPurged = isPurged ? true : false
      }
    }

    if (search) {
      data.search = search;
    }
    props.exportReport(data);
  }

  const handleSearchInput = (event) => {
    event.preventDefault()
    let canceled = false;
    let timer;
    const newCancelFn = () => {
      canceled = true;
      clearTimeout(timer);
    };

    const query = event.target.value;

    if (query) {
      timer = setTimeout(() => {
        setSearch(query);
        setActivePage(1);
      }, 600);
    } else setSearch("");
    setCancelFn(() => newCancelFn);
  };

  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setRowsPerPage(value);
    setActivePage(1);
  };

  const handleUserInfo = (user) => {
    history_.push("/admin/user/detail/"+ user._id);
  };

  const handlePurgeCoin = () => {
    const modal = warning();
    modal
      .then((isConfirm) => {
        if (isConfirm) {
          if (!hasPermission) return permissionError();
          const data = {
            type: historyType,
          };
          if (sDate !== "ALL" && eDate !== "ALL") {
            data.startDate = sDate;
            data.endDate = eDate;
          }
          if (search) {
            data.search = search;
          }
          props.purgeCoin(data);
          alert("Done!", `Coin has been purged`, "success");
        }
      })
      .catch((err) => console.log(err));
  }
  function getUserAgencyName(agency) {
    if (agency?.name) {
      // 检查是否有有效的 userAgency
      return agency.name; // 返回机构名称
    } else {
      return "-"; // 如果没有有效的 userAgency，返回默认值
    }
  }

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3">Report</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/report" className="text-danger">
                    Report
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 float-left mb-xl-3 mb-5">
                  <div className="d-flex flex-wrap justify-content-between">
                    <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                      <button
                        className="collapsed btn btn-info ml-5"
                        value="check"
                        data-toggle="collapse"
                        data-target="#datePicker"
                        onClick={collapsedDatePicker}
                        style={{ marginRight: 5 }}
                      >
                        Analytics
                        <ExpandMoreIcon />
                      </button>
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: 5 }}
                        onClick={() => exportThisReport()}
                      >Export</button>
                      <p style={{ paddingLeft: 4, fontSize: 18 }} className="my-2">
                        {dayjs(sDate).format("DD/MM/YYYY") + " to " + dayjs(eDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-5">
                      <form action="">
                        <div className="input-group border rounded-pill">
                          <div className="input-group-prepend border-0">
                            <div id="button-addon4" className="btn text-danger">
                              <i className="fas fa-search mt-2"></i>
                            </div>
                          </div>
                          <input
                            type="search"                   
                            id="searchBar"
                            defaultValue={search != 'ALL' ? search : ""}
                            autoComplete="off"
                            placeholder="What're you searching for?"
                            aria-describedby="button-addon4"
                            className="form-control bg-none border-0 rounded-pill searchBar"
                            onChange={(e) => {
                              handleSearchInput(e);
                            }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 float-right mt-3 mt-lg-0 mt-xl-0  align-sm-left d-md-flex d-lg-flex justify-content-end">
                  <ul
                    className="list-unstyled align-sm-left d-md-flex d-lg-flex pt-2"
                    id="manageVideoFeed"
                  >
                    <li className="px-2 my-2 my-md-0">
                      <a
                        href={() => false}
                        className="pointer-cursor badge bg-warning"
                        onClick={() => {
                          setHistoryType("rCoin");
                          setActivePage(1);
                        }}
                      >
                        RCoin Report
                      </a>
                    </li>

                    <li className="px-2 my-2 my-md-0">
                      <a
                        href={() => false}
                        className="pointer-cursor badge bg-primary"
                        onClick={() => {
                          setHistoryType("liveStreaming");
                          setActivePage(1);
                        }}
                      >
                        LiveStreaming Report
                      </a>
                    </li>

                  </ul>
                </div>
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-3"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        onChange={(item) => {
                          setDate([item.selection]);
                          const dayStart = dayjs(
                            item.selection.startDate
                          ).format("YYYY-MM-DD 00:00:00");
                          const dayEnd = dayjs(item.selection.endDate).format("YYYY-MM-DD 23:59:59");
                          setActivePage(1);
                          setsDate(dayStart);
                          seteDate(dayEnd);
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              {(historyType === "rCoin") && (
                <>
                  <div className="d-flex justify-content-between align-items-baseline mt-3">
                    {historyType === "rCoin" ? (
                      <h4 className="text-warning">RCoin Report</h4>
                    ) : null}

                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <span className="text-danger mr-1">Filter:&nbsp;&nbsp;&nbsp;</span>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            setIsPurged(parseInt(e.target.value));
                            setActivePage(1);
                          }}
                        >
                          <option value={2}>Include Purged</option>
                          <option value={0}>Exclude Purged</option>
                          <option value={1}>Purged Only</option>
                        </select>
                      </div>

                      <span className="text-danger" style={{ paddingLeft: 10 }}>
                        Total Balance :
                        <span style={{ color: 'lightgrey' }}>&nbsp;{balanceIncome}</span>
                      </span>
                      <span className="text-danger" style={{ paddingLeft: 10 }}>
                        Total Income :
                        <span className="text-info">&nbsp;{income}</span>
                      </span>
                      <span className="text-danger" style={{ paddingLeft: 10 }}>
                        Total Outgoing :
                        <span style={{ color: '#e3242b' }}>&nbsp;{outgoing}</span>
                      </span>
                    </div>
                  </div>
                  {/* <div className="float-end">
                    <button
                      className="btn btn-danger"
                      onClick={handlePurgeCoin}
                    >Purge Coin</button>
                  </div> */}
                  <table class="table table-striped mt-5">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name (Username)</th>
                        <th>Agency</th>
                        <th>Detail</th>
                        {/* <th>Diamond</th> */}
                        <th>RCoin</th>
                        <th>Remarks</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        data.map((data, index) => {
                          return (
                            <tr key={startIndex + index}>
                              <td>{startIndex + index + 1}</td>
                              <td
                                onClick={() => handleUserInfo(data.owner)}
                                className="pointer-cursor"
                              >{data.owner?.name + (data.owner?.username ? " (" + data.owner?.username + ")" : "")}</td>
                              <td>{getUserAgencyName(data.agency)}</td>
                              {data.type === 1 && (
                                <td>
                                  <span>
                                    <img
                                      src={diamond}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  RCoin converted to diamonds
                                </td>
                              )}
                              {data.type === 2 && <td>Diamond purchase</td>}
                              {data.type === 3 && (
                                <td>
                                  <span>
                                    <img
                                      src={videocall}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  Call with&nbsp;
                                  <a
                                    href={() => false}
                                    onClick={() => handleUserInfo(data.userId)}
                                    className="pointer-cursor text-danger"
                                  >
                                    @{data.userName}
                                  </a>
                                </td>
                              )}
                              {data.type === 4 && (
                                <td>
                                  <span>
                                    <img
                                      src={ads}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  Watching ads
                                </td>
                              )}
                              {data.type === 5 && (
                                <td>
                                  <span>
                                    <img
                                      src={moneybag}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  Login bonus
                                </td>
                              )}
                              {data.type === 6 && (
                                <td>
                                  <span>
                                    <img
                                      src={moneybag}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  Referral bonus
                                </td>
                              )}
                              {data.type === 7 && (
                                <td>
                                  <span>
                                    <img
                                      src={withdraw}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  CashOut [Redeem]
                                </td>
                              )}
                              {data.type === 8 && (
                                <td>
                                  <span>
                                    <img
                                      src={male}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  By Admin
                                </td>
                              )}
                              {data.type === 9 && (
                                <td>
                                  <span>
                                    <img
                                      src={male}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  {(data.isIncome) ? "Receive From " : "Transfer To "}{data.userId.username}.
                                  {data.remarks ? " Remarks: " + data.remarks : ""}
                                </td>
                              )}
                              {data.type === 10 && (
                                <td>
                                  <span>
                                    <img
                                      src={male}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  Purchase VIP Plan: {data.data.vipPlanName}.
                                </td>

                              )}
                              {data.type === 0 && data.userName === null ? (
                                <td>
                                  <span>
                                    <img
                                      src={gift}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  Gift Broadcast during livestream by
                                  {data.owner.name ? " " + data.owner.name : " you"}
                                </td>
                              ) : data.type === 0 ? (
                                <td
                                  onClick={() => handleUserInfo(data.userId)}
                                  className="pointer-cursor"
                                >
                                  <span>
                                    <img
                                      src={gift}
                                      alt="icon"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  gift received from @{data.userName}
                                </td>
                              ) : null}

                              {/* {data.diamond ? (
                                data.isIncome ? (
                                  data.diamond !== 0 ? (
                                    <td className="text-success">
                                      +{data.diamond}
                                    </td>
                                  ) : (
                                    <td>{data.diamond}</td>
                                  )
                                ) : data.diamond !== 0 ? (
                                  <td className="text-red">-{data.diamond}</td>
                                ) : (
                                  <td>{data.diamond}</td>
                                )
                              ) : (
                                <td>-</td>
                              )} */}

                              {data.rCoin ? (
                                data.isIncome ? (
                                  data.rCoin !== 0 ? (
                                    <td className="text-success">
                                      +{data.rCoin}
                                    </td>
                                  ) : (
                                    <td>{data.rCoin}</td>
                                  )
                                ) : data.rCoin !== 0 ? (
                                  <td className="text-red">-{data.rCoin}</td>
                                ) : (
                                  <td>{data.rCoin}</td>
                                )
                              ) : (
                                <td>-</td>
                              )}
                              <td>{data.remarks ?? "-"}</td>
                              <td>{dayjs(data.date).format("DD/MM/YYYY hh:mm:ss A")}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="7" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
              {historyType === "liveStreaming" && (
                <>
                  <div className="d-flex justify-content-between mt-3">
                    <h4 className="text-primary">LiveStreaming Report</h4>
                    <span className="text-danger ">
                      Total Income :
                      <span className="text-info">
                        &nbsp;{liveStreamingIncome}
                      </span>
                    </span>
                  </div>
                  <table class="table table-striped mt-5">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name (Username)</th>
                        <th>Start Date</th>
                        <th>Duration</th>
                        <th>Joined User</th>
                        <th>Received Gift</th>
                        <th>Received RCoin</th>
                        <th>Comments</th>
                        <th>New Followers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        data.map((data, index) => {
                          return (
                            <tr key={startIndex + index}>
                              <td>{startIndex + index + 1}</td>
                              <td
                                onClick={() => handleUserInfo(data.owner)}
                                className="pointer-cursor"
                              >{data.owner?.name + (data.owner?.username ? " (" + data.owner?.username + ")" : "")}</td>
                              {/* <td>{data.startTime}</td> */}
                              <td>{dayjs(data.createdAt).format('YYYY-MM-DD, HH:mm:ss')}</td>
                              <td>{dayjs().startOf('day').add(data.duration, 'second').format('HH:mm:ss')}</td>

                              <td className="text-center text-primary">
                                {/* {data.user} */}
                                {data.viewerCount}
                              </td>
                              <td className="text-center text-info">
                                {/* {data.gifts} */}
                                {data.giftCount}
                              </td>
                              <td className="text-center text-warning">
                                {/* {data.rCoin} */}
                                {data.totalGiftPoint}
                              </td>
                              <td className="text-center text-success">
                                {/* {data.comments} */}
                                {data.commentCount}
                              </td>
                              <td className="text-center text-danger">
                                {/* {data.fans} */}
                                {data.increaseFollowerCount}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="9" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={totalReport}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { reportHistory, exportReport, purgeCoin })(
  Report
);
