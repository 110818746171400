import React, { useEffect, useRef, useState } from "react";

//dayjs
import dayjs from "dayjs";
import { getTopReceiver, getTopSender } from "../../store/ranking/action";

//jquery
import $, { getJSON } from "jquery";
//redux
import { connect, useSelector } from "react-redux";

//routing
import { Link, useHistory } from "react-router-dom";

//image
import Male from "../../assets/images/male.png";

//pagination
import Pagination from "../../pages/Pagination";

//Date Range Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { convertThousandNumbers, formatDateTime } from "../../util/Helper";
import { Tooltip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "0 !important"
    },
    removeElement: {
        display: "none !important"
    },
    header: {
        paddingBottom: "8px",
        background: "white !important"
    },
    month: {
        padding: "8px 0"
    },
    selectedMonth: {
        background: "gray !important",
        padding: "8px 0"
    },
    inputBox: {
        // background: 'white !important',
        border: "none"
    }
}));

const Ranking = (props) => {
    const ref = useRef();
    const classes = useStyles();
    const history_ = useHistory();

    const [data, setData] = useState([]);
    const searchParams = new URLSearchParams(window.location.search);

    const [activePage, setActivePage] = useState(searchParams.get("page")??1);
    const [rowsPerPage, setRowsPerPage] = useState(searchParams.get('rows')??10);
    const [cancelFn, setCancelFn] = useState(() => () => { });
    

    const [date, setDate] = useState([]);
    const [type, setType] = useState(searchParams.get('typeS')??"monthly");
    const [sDate, setsDate] = useState(searchParams.get('startDate') ? new Date(searchParams.get('startDate')) :dayjs().toDate());
    const [selectedCategory, setSelectedCategory] = useState(searchParams.get('category')??"RCoin Receiver Ranking");
    const [typeTitle,setTypeTitle] = useState(searchParams.get('title')??"Total RCoin Received");
    const [country,setCountry] = useState(searchParams.get('countryS')??"");
    const [countries, setCountries] = useState([]);   //get country list from url
    const startIndex = (activePage - 1) * rowsPerPage;

    const collapsedDatePicker = () => {
        $("#datePicker").toggleClass("collapse");
      };

      const sortDataTopReceiver = () => {
        setActivePage(1);
        setTypeTitle("Total RCoin Received");
        setSelectedCategory("RCoin Receiver Ranking");
        const sorted = [...data].sort((a, b) => b.accumulativeRCoin - a.accumulativeRCoin);
        setData(sorted);
    };

    const sortDataTopSender = async () => {
        setActivePage(1);
        setSelectedCategory("Gift Sender Ranking");
        setTypeTitle("Total Gift Sent");
        const sortedData = [...data].sort((a, b) => b.spentCoin - a.spentCoin);
        setData(sortedData);
      };

      const handleCountryChange = (event) => {
        setCountry(event.target.value)
      };

      const handleTypeChange = (event) => {
        setType(event.target.value);
      };

      useEffect(() => {
        const fetchCountries = async () => {
            const response = await fetch('https://countriesnow.space/api/v0.1/countries/iso');
            const data = await response.json();
            setCountries(data.data); // Update state with the data
          
        };
        fetchCountries();
    },[]);

    useEffect(() => {

        let queryString  = "?page=" + activePage
        if (rowsPerPage) queryString += `&rows=${rowsPerPage}`;
        if (country) queryString += `&countryS=${country}`;
        if (type) queryString += `&typeS=${type}`;
        if (typeTitle) queryString += `&title=${typeTitle}`;
        if (selectedCategory) queryString += `&category=${selectedCategory}`;
        if (sDate) queryString += `&startDate=${sDate.toISOString()}`;
        window.history.pushState("", "", window.location.pathname + queryString);

        const fetchReceiverData = async () => {

          const result = await props.getTopReceiver(type,dayjs(sDate).format("YYYY-MM-DD"), activePage, rowsPerPage,country, selectedCategory,typeTitle);
          setData(result || []); // Ensure result is an array
        };
    
        const fetchSenderData = async () => {

          const result = await props.getTopSender(type,dayjs(sDate).format("YYYY-MM-DD"), activePage, rowsPerPage,country, selectedCategory,typeTitle);
          setData(result || []); // Ensure result is an array
        };   

        if (selectedCategory === "RCoin Receiver Ranking") {
          fetchReceiverData();
        } else if (selectedCategory === "Gift Sender Ranking") {
          fetchSenderData();
        }
      }, [activePage, rowsPerPage,type, sDate, selectedCategory,country]);
    
    let buttonText = "RCoin Receiver Ranking";
    if (selectedCategory === "RCoin Receiver Ranking") {
        buttonText = "RCoin Receiver Ranking";
    } else if (selectedCategory === "Gift Sender Ranking") {
        buttonText = "Gift Sender Ranking";
    }

    // Cancel previous typing.
    useEffect(() => () => cancelFn(), [cancelFn]);

    const { rankUser, total } = useSelector(
        (state) => state.rankingList
    );

    useEffect(() => {
        window.addEventListener('click', function (e) {
            if (!document.getElementById('datePicker')?.contains(e.target)) {
                $("#datePicker").removeClass("show");
            }
        });
    }, []);

    useEffect(() => {
        setData(rankUser);
    }, [rankUser]);


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        handlePageChange(1);
        setRowsPerPage(value);
    };
   
    const handleUserInfo = (user) => {
        history_.push("/admin/user/detail/"+ user._id);
    };

    return (
        <>
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className="mb-3">Top Ranking List</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Top Ranking List
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>       

            <div className="row">
                <div className="col">
                    <div className="card" id="card">
                        <div className="card-header pb-0">
                            <div className="row my-3">
                                <div className="mb-2">
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                        style={{ marginRight: 5 }}
                                        onClick={sortDataTopReceiver}
                                    >
                                        RCoin Receiver Ranking
                                    </button>

                                    <button 
                                        type="button" 
                                        className="btn btn-info" 
                                        onClick={sortDataTopSender}
                                        style={{ marginRight: 5 }}
                                    >
                                        Gift Sender Ranking
                                    </button>

                                </div>
                                <div className="container table-responsive">
                                <label htmlFor="start" className="px-3 d-inline" style={{ whiteSpace: "pre" }}
                                >Selected Date:</label>
                                <div style={{ display: 'inline-block' }}>
                                    <DatePicker
                                        selected={sDate}
                                        onChange={(date) => {
                                            setActivePage(1);
                                            setsDate(date);
                                        }}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="yyyy/MM/dd"
                                        placeholderText="Select a date"
                                        className="form-control"
                                        
                                    />
                                    </div>
                                    <label htmlFor="interval"></label>
                                    <div style={{ display: 'inline-block' }}>
                                <select id="interval" value={type} onChange={handleTypeChange} 
                                    className="form-control " style={{ marginLeft: 5 }}>
                                    
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                                </div>

                                <label htmlFor="countrySelect" className="px-3 d-inline" style={{ whiteSpace: "pre" }}
                                >Selected Country:</label>
                                <div style={{ display: 'inline-block' }}>
                                <select id="countrySelect" value={country} onChange={handleCountryChange} className="form-control " style={{ marginLeft: 5 }}                                  >
                                    <option value="">All</option>
                                    {countries.map((country, index) => (
                                    <option key={index} value={country.name}>
                                        {country.name}
                                    </option>
                                    ))}
                                </select>
                                </div>
                       
                                </div>
                            </div>

                            <div className="row my-3">
                            <div className="col">
                             <h4>{buttonText}</h4>
                             </div>

                            </div>
                            </div>
                        <div className="card-body card-overflow pt-0">
                            <table className="table table-striped mt-5">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Image</th>
                                        <th>Name (Username)</th>
                                        <th>Gender</th>
                                        <th>Country</th>
                                        <th>Follower</th>
                                        <th>Following</th>
                                        <th>{typeTitle}</th>                                
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? (
                                        data.map((data, index) => {
                                            return (
                                                <tr key={startIndex + index}>
                                                    <td>{startIndex + index + 1}</td>
                                                    <td>
                                                        <img
                                                            height="50px"
                                                            width="50px"
                                                            alt="app"
                                                            src={data.user.image ? data.user.image : Male}
                                                            onClick={() => handleUserInfo(data)}
                                                            className="pointer-cursor"
                                                            style={{
                                                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                                border: "2px solid #fff",
                                                                borderRadius: 10,
                                                                float: "left",
                                                                objectFit: "cover",
                                                            }}
                                                        />
                                                    </td>
                                                    <td
                                                     onClick={() => handleUserInfo(data)}
                                                     className="pointer-cursor"
                                                     >{data.user.name + (data.user.username ? " (" + data.user.username + ")" : "")}</td>
                                                    <td>{data.user.gender}</td>
                                                    <td> {data.user.country} </td>
                                                    <td>{data.user.followers}</td>
                                                    <td>{data.user.following}</td>
                                                    <td>
                                                    {data.totalReceive ?? data.totalSent}
                                                    </td>
                                    
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="10" align="center">
                                                Nothing to show!!
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <Pagination
                                activePage={parseInt(activePage)}
                                rowsPerPage={rowsPerPage}
                                userTotal={total}
                                handleRowsPerPage={handleRowsPerPage}
                                handlePageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { getTopReceiver, getTopSender })(Ranking);
