import axios from "axios";
import { Toast } from "../../util/Toast";
import { EXPORT_LIVE_SUMMARY_REPORT, GET_STREAMER_LIVE_SUMMARY, GET_STREAMER_LIVE_SUMMARY_DETAIL } from "./types";
import { formatDateTime } from "../../util/Helper";
import dayjs from "dayjs";

export const getStreamerLiveSummary =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    // let url = `live-streaming-history/streamer-task-summary?start=${start}&limit=${limit}&startDate=${formatDateTime(sDate)}&endDate=${formatDateTime(eDate)}`
    let url = `live-room/streamer-task-summary?start=${start}&limit=${limit}&startDate=${formatDateTime(sDate)}&endDate=${formatDateTime(eDate)}`
    if (searchValue != "ALL" && searchValue!=null){
      url += "&search="+searchValue
    }
    axios.get(url)
      .then((res) => {
        if (res.status) {
        
          dispatch({
            type: GET_STREAMER_LIVE_SUMMARY,
            payload: res.data?.data,
            total:res.data?.total,
            additionalInfo: res.data?.additionalInfo
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

export const getLiveSummaryDetails =
  (userID, date) => (dispatch) => {
    // let url = `live-streaming-history/streamer-task-summary/${userID}?selectedDate=${date}`
    let url = `live-room/streamer-task-summary/${userID}?selectedDate=${date}`
    axios.get(url)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_STREAMER_LIVE_SUMMARY_DETAIL,
            payload: res.data
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

export const exportLiveSummaryReport = (start, limit, searchValue, sDate, eDate) => (dispatch) => {
  // let url = `live-streaming-history/streamer-task-summary/export?start=${start}&limit=${limit}&startDate=${formatDateTime(sDate)}&endDate=${formatDateTime(eDate)}`
  let url = `live-room/streamer-task-summary/export?start=${start}&limit=${limit}&startDate=${formatDateTime(sDate)}&endDate=${formatDateTime(eDate)}`
  if (searchValue != "ALL" && searchValue != null) {
    url += "&search=" + searchValue
  }
  axios
    .get(url)
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: EXPORT_LIVE_SUMMARY_REPORT, payload: { csv: res.data, month: dayjs(start).format("MMM") }, });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};