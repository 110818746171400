import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_VIP_PLAN_DIALOG } from "../../store/vipPlan/types";

//action
import { createNewVIPPlan, editVIPPlan } from "../../store/vipPlan/action";

import { permissionError } from "../../util/Alert";
import { baseURL } from "../../util/Config";

const VIPPlanDialog = (props) => {
    const dispatch = useDispatch();

    const { dialog: open, dialogData } = useSelector((state) => state.vipPlan);

    const hasPermission = useSelector((state) => state.admin.admin.flag);

    const [mongoId, setMongoId] = useState("");
    const [validity, setValidity] = useState("");
    const [validityType, setValidityType] = useState("");
    const [dollar, setDollar] = useState("");
    const [rupee, setRupee] = useState("");
    const [diamond, setDiamond] = useState("");
    const [order, setOrder] = useState("");
    const [tag, setTag] = useState("");
    const [productKey, setProductKey] = useState("");
    const [name, setName] = useState("");
    const [imageData, setImageData] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [description, setDescription] = useState("");

    const [errors, setError] = useState({
        validity: "",
        name: "",
        diamond: "",
        productKey: "",
    });

    useEffect(() => {
        if (dialogData) {
            setMongoId(dialogData._id);
            setValidity(dialogData.validity);
            setValidityType(dialogData.validityType);
            setDollar(dialogData.dollar);
            setRupee(dialogData.rupee);
            setDiamond(dialogData.diamond);
            setOrder(dialogData.order);
            setTag(dialogData.tag);
            setProductKey(dialogData.productKey);
            setName(dialogData.name);
            setImagePath(baseURL + dialogData.icon);
            setDescription(dialogData.description);
        }
    }, [dialogData]);

    useEffect(
        () => () => {
            setError({
                validity: "",
                dollar: "",
                rupee: "",
                diamond: "",
                order: "",
                productKey: "",
                name: "",
            });
            setMongoId("");
            setValidity("");
            setValidityType("");
            setTag("");
            setDollar("");
            setRupee(0);
            setDiamond("");
            setOrder("");
            setProductKey("");
            setName("");
            setImageData(null);
            setImagePath(null);
            setDescription("");
        },
        [open]
    );

    const closePopup = (reason) => {
        if(reason.type == "click")
        dispatch({ type: CLOSE_VIP_PLAN_DIALOG });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validity || !name || !diamond) {
            const error = {};

            if (!validity) error.validity = "Validity is required!";
            if (!diamond) error.diamond = "Diamond is required!";
            if (!name) error.name = "Name is required!";

            // if (!dollar) error.dollar = "Dollar is required!";
            // if (!rupee) error.rupee = "Rupee is required!";
            // if (!productKey) error.productKey = "Product Key is required!";

            return setError({ ...error });
        }

        if(description && description?.length > 160){
            return setError({ ...errors, description: "Description cannot exceed 160 characters" });
        }

        const validityValid = isNumeric(validity);
        if (!validityValid) {
            return setError({ ...errors, validity: "Invalid Validity!!" });
        }
        // const dollarValid = isNumeric(dollar);
        // if (!dollarValid) {
        //     return setError({ ...errors, dollar: "Invalid Dollar!!" });
        // }
        // const rupeeValid = isNumeric(rupee);
        // if (!rupeeValid) {
        //     return setError({ ...errors, rupee: "Invalid Rupee!!" });
        // }
        const diamondValid = isNumeric(diamond);
        if (!diamondValid) {
            return setError({ ...errors, dollar: "Invalid Diamond!!" });
        }
        // const orderValid = isNumeric(order);
        // if (!orderValid) {
        //     return setError({ ...errors, dollar: "Invalid order!!" });
        // }

        let formData = new FormData();
        formData.append("validity", validity);
        formData.append("validityType", validityType ? validityType : "day");
        // formData.append("dollar", dollar);
        // formData.append("rupee", rupee);
        formData.append("diamond", diamond ?? 0);
        formData.append("order", order ?? 1);
        // formData.append("tag", tag);
        // formData.append("productKey", productKey);
        formData.append("name", name);
        formData.append("description",description);
        if (imageData) formData.append("icon", imageData);


        if (!hasPermission) return permissionError();
        if (mongoId) {
            props.editVIPPlan(mongoId, formData);
        } else {
            props.createNewVIPPlan(formData);
        }
    };

    const isNumeric = (value) => {
        const val = value === "" ? 0 : value;
        const validNumber = /^\d+$/.test(val);
        return validNumber;
    };

    const handleInputImage = (e) => {
        if (e.target.files[0]) {
            setImageData(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImagePath(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="responsive-dialog-title"
                onClose={closePopup}
                disableEscapeKeyDown
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="responsive-dialog-title">
                    <span className="text-danger font-weight-bold h4"> Plan </span>
                </DialogTitle>

                <IconButton
                    style={{
                        position: "absolute",
                        right: 0,
                    }}
                >
                    <Tooltip title="Close">
                        <Cancel className="text-danger" onClick={closePopup} />
                    </Tooltip>
                </IconButton>
                <DialogContent>
                    <div className="modal-body pt-1 px-1 pb-3">
                        <div className="d-flex flex-column">
                            <form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-gray mb-2">Validity</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                required=""
                                                placeholder="1"
                                                value={validity}
                                                onChange={(e) => {
                                                    setValidity(e.target.value);
                                                    if (!e.target.value) {
                                                        return setError({
                                                            ...errors,
                                                            validity: "Validity is Required!",
                                                        });
                                                    } else {
                                                        return setError({
                                                            ...errors,
                                                            validity: "",
                                                        });
                                                    }
                                                }}
                                            />
                                            {errors.validity && (
                                                <div className="ml-2 mt-1">
                                                    {errors.validity && (
                                                        <div className="pl-1 text__left">
                                                            <span className="text-red">{errors.validity}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-gray mb-2">Validity Type</label>
                                            <select
                                                class="form-select form-control"
                                                aria-label="Default select example"
                                                value={validityType}
                                                onChange={(e) => {
                                                    setValidityType(e.target.value);
                                                }}
                                            >
                                                <option value="day" selected>
                                                    Day
                                                </option>
                                                <option value="month">Month</option>
                                                <option value="year">Year</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group mt-4">
                                    <label className="mb-2 text-gray">Product Key</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        required=""
                                        placeholder="android.test.purchased"
                                        value={productKey}
                                        onChange={(e) => {
                                            setProductKey(e.target.value);
                                            if (!e.target.value) {
                                                return setError({
                                                    ...errors,
                                                    productKey: "Product Key is Required !",
                                                });
                                            } else {
                                                return setError({
                                                    ...errors,
                                                    productKey: "",
                                                });
                                            }
                                        }}
                                    />
                                    {errors.productKey && (
                                        <div className="ml-2 mt-1">
                                            {errors.productKey && (
                                                <div className="pl-1 text__left">
                                                    <span className="text-red">{errors.productKey}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    </div>
                                    </div>
                                 
                                    <div className="col-md-6">
                                        <div className="form-group mt-4">
                                            <label className="text-gray mb-2">Order</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                required=""
                                                placeholder="100"
                                                value={order}
                                                onChange={(e) => {
                                                    setOrder(e.target.value);
                                                    if (!e.target.value) {
                                                        return setError({
                                                            ...errors,
                                                            order: "Order is Required !",
                                                        });
                                                    } else {
                                                        return setError({
                                                            ...errors,
                                                            order: "",
                                                        });
                                                    }
                                                }}
                                            />
                                            {errors.order && (
                                                <div className="ml-2 mt-1">
                                                    {errors.order && (
                                                        <div className="pl-1 text__left">
                                                            <span className="text-red">{errors.order}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div> */}
                                
                                {/* <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mt-4">
                                            <label className="text-gray mb-2">Dollar</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                required=""
                                                placeholder="10"
                                                value={dollar}
                                                onChange={(e) => {
                                                    setDollar(e.target.value);
                                                    if (!e.target.value) {
                                                        return setError({
                                                            ...errors,
                                                            dollar: "Dollar is Required !",
                                                        });
                                                    } else {
                                                        return setError({
                                                            ...errors,
                                                            dollar: "",
                                                        });
                                                    }
                                                }}
                                            />
                                            {errors.dollar && (
                                                <div className="ml-2 mt-1">
                                                    {errors.dollar && (
                                                        <div className="pl-1 text__left">
                                                            <span className="text-red">{errors.dollar}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                       <div className="col-md-6">
                                        <div className="form-group mt-4">
                                            <label className="text-gray mb-2">Tag</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required=""
                                                placeholder="20% OFF"
                                                value={tag}
                                                onChange={(e) => {
                                                    setTag(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div> */}

                                    {/* <div className="col-md-6">
                                        <div className="form-group mt-4">
                                            <label className="text-gray mb-2">Diamond</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                required=""
                                                placeholder="10"
                                                value={diamond}
                                                onChange={(e) => {
                                                    setDiamond(e.target.value);
                                                    if (!e.target.value) {
                                                        return setError({
                                                            ...errors,
                                                            diamond: "Diamond is Required !",
                                                        });
                                                    } else {
                                                        return setError({
                                                            ...errors,
                                                            diamond: "",
                                                        });
                                                    }
                                                }}
                                            />
                                            {errors.diamond && (
                                                <div className="ml-2 mt-1">
                                                    {errors.diamond && (
                                                        <div className="pl-1 text__left">
                                                            <span className="text-red">{errors.diamond}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
                                    
                                

                                <div className="row ">
                                    <div className="col-md-6">
                                        <div className="form-group mt-4">
                                            <label className="text-gray mb-2">Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required={true}
                                                placeholder="Plan Name"
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                }}
                                            />
                                              {errors.name && (
                                                <div className="ml-2 mt-1">
                                                    {errors.name && (
                                                        <div className="pl-1 text__left">
                                                            <span className="text-red">{errors.name}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mt-4">
                                            <label className="text-gray mb-2">Diamond</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                required={true}
                                                placeholder="10"
                                                value={diamond}
                                                onChange={(e) => {
                                                    setDiamond(e.target.value);
                                                    if (!e.target.value) {
                                                        return setError({
                                                            ...errors,
                                                            diamond: "Diamond is Required !",
                                                        });
                                                    } else {
                                                        return setError({
                                                            ...errors,
                                                            diamond: "",
                                                        });
                                                    }
                                                }}
                                            />
                                            {errors.diamond && (
                                                <div className="ml-2 mt-1">
                                                    {errors.diamond && (
                                                        <div className="pl-1 text__left">
                                                            <span className="text-red">{errors.diamond}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>    
                                </div>

                                <div className="form-group mt-4">
                                    <label className="mb-2 text-gray">Icon</label>
                                    <input
                                        type="file"
                                        className="form-control form-control-sm"
                                        accept="image/*"
                                        required=""
                                        onChange={handleInputImage}
                                    />
                                    {errors.image && (
                                        <div className="ml-2 mt-1">
                                            {errors.image && (
                                                <div className="pl-1 text__left">
                                                    <span className="text-red">{errors.image}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {imagePath && (
                                        <>
                                            <img
                                                height="70px"
                                                width="70px"
                                                alt="app"
                                                src={imagePath}
                                                style={{
                                                    boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                    // border: "2px solid #fff",
                                                    borderRadius: 10,
                                                    marginTop: 10,
                                                    float: "left",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                                
                                
                                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                                </div>
                                <div className = "row mt-4">
                                <div className="form-group mt-15">
                                <label className="text-gray mb-2">Description</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                required=""
                                                placeholder="Set description for this VIP plan (max 160 characters)"
                                                style={{ height: "100px"}}
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(e.target.value);
                                                    if(description.length>160){
                                                        return setError({
                                                            ...errors,
                                                            description: "Maximum characters for description is 160!"
                                                        });
                                                    } else {
                                                        return setError({
                                                            ...errors,
                                                            dollar: ""
                                                        });
                                                    }
                                                }}
                                            />
                                            {errors.description && (
                                                <div className="ml-2 mt-1">
                                                    {errors.description && (
                                                        <div className="pl-1 text__left">
                                                            <span className="text-red">{errors.description}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                                        onClick={closePopup}
                                    >
                                        Close
                                    </button>
                                    <button type="button" className="btn btn-round float__right btn-danger" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                    </div>
                                </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default connect(null, { createNewVIPPlan, editVIPPlan })(VIPPlanDialog);
