import axios from "axios";
import { baseURL, key } from "../../util/Config";
import { Toast } from "../../util/Toast";
import {
  GET_ANNOUNCEMENT,
  GET_ALL_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  CLOSE_ANNOUNCEMENT_DIALOG,
  CREATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
} from "./types";

export const getAllAnnouncement = (id, start, limit, sDate, eDate) => (dispatch) => {
  const url =
    id !== null
      ? `announcement?announcementId=${id}`
      : `announcement/all?start=${start}&limit=${limit}&startDate=${sDate}&endDate=${eDate}`;
  axios
    .get(url)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: GET_ALL_ANNOUNCEMENT,
          payload: { announcement: res.data.announcement, total: res.data.total },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const createNewAnnouncement = (data) => (dispatch) => {
  axios
    .post(`announcement`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Announcement created successfully!");
        dispatch({ type: CLOSE_ANNOUNCEMENT_DIALOG });
        dispatch({ type: CREATE_ANNOUNCEMENT, payload: res.data.announcement });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const editAnnouncement = (announcementId, data) => (dispatch) => {
  axios
    .patch(`announcement/${announcementId}`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Announcement updated successfully!");
        dispatch({ type: CLOSE_ANNOUNCEMENT_DIALOG });
        dispatch({
          type: UPDATE_ANNOUNCEMENT,
          payload: { data: res.data.announcement, id: announcementId },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const deleteAnnouncement = (announcementId) => (dispatch) => {
  axios
    .delete(`announcement/${announcementId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_ANNOUNCEMENT, payload: announcementId });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

