import axios from "axios";
import { Toast } from "../../util/Toast";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AGENT_SWITCH,
  FEATURED_SWITCH,
  BLOCK_UNBLOCK_SWITCH,
  STREAMER_SWITCH,
  GET_USER,
  GET_HISTORY,
  EDIT_COIN,
  UPDATE_USER,
  MODERATOR_SWITCH
} from "./types";
import { SET_ADMIN } from "../../store/admin/types";

export const getUser =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
  let url =  `getUsers?start=${start}&limit=${limit}&startDate=${sDate}&endDate=${eDate}`
  url+= `&search=${searchValue??"ALL"}`

    axios.get(url).then((res) => {
        if (res.status) {
          let resData = res.data
          let male, female;
          if (resData.maleFemale) {
            // eslint-disable-next-line
            resData.maleFemale.map((data) => {
              if (data._id === "Female") return (female = data.gender);
              if (data._id === "Male") return (male = data.gender);
            });
          }
          dispatch({
            type: GET_USER,
            payload: {
              user: resData.user,
              activeUser: resData.activeUser,
              total: resData.total,
              male: male,
              female: female,
            },
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

export async function getUserDetail(userId) {
  let url = `user/${userId}`;
  return new Promise((resolve, reject) => {
    axios.get(url)
        .then((response) => {
            const { data } = response;
            resolve(data);
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.data) {
                    reject({ http_code: error.response.status, data: error.response.data });
                } else {
                    reject(error.response.data);
                }
            } else {
                reject(error?.message);
            }
        });
  });
}

export const handleAgentSwitch = (userId) => (dispatch) => {
  axios
    .patch(`updateAgent/${userId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: AGENT_SWITCH, payload: res.data.user });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const handleFeaturedSwitch = (userId) => (dispatch) => {
  axios
    .patch(`updateFeatured/${userId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: FEATURED_SWITCH, payload: res.data.user });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const handleBlockUnblockSwitch = (userId) => (dispatch) => {
  axios
    .patch(`blockUnblock/${userId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: BLOCK_UNBLOCK_SWITCH, payload: res.data.user });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const handleIsStreamerSwitch = (userId) => (dispatch) => {
  axios
    .patch(`updateStreamer/${userId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: STREAMER_SWITCH, payload: res.data.user });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const handleIsModeratorSwitch = (userId) => (dispatch) => {
  axios
    .patch(`updateModerator/${userId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: MODERATOR_SWITCH, payload: res.data.user });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const userHistory = (data) => (dispatch) => {
  axios
    .post(`history`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_HISTORY, payload: res.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const editCoin = (data) => (dispatch) => {
  axios
    .post(`/user/addLessCoin`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_COIN,
          payload: { data: res.data.user, id: data.userId },
        });
        Toast("success", "Update Successful!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const updateUserInfo = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
    .post(`/user/update`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_USER,
          payload: { data: res.data.user, id: data.userId },
        });
        Toast("success", "Update Successful!!");
        resolve(true)
      } else {
        Toast("error", res.data.message);
        resolve(false)
      }
    })
    .catch((error) => Toast("error", error.message));
  });
};
