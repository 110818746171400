import dayjs from "dayjs";
import { GET_PK_SESSIONS } from "./types"
import { exportData } from "../../util/Helper";

const initialState = {
  pkLiveRoom: [],
  totalPKLiveRoom: 0,
  dialog: false,
  dialogData: null,
};

const pkLiveRoomSessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PK_SESSIONS:
      return {
        ...state,
        pkLiveRoom: action.payload,
        totalPKLiveRoom: action.total,
      };
    default:
      return state;
  }
};

export default pkLiveRoomSessionsReducer;
