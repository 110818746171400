import {
  GET_GAME_LIST,
  SYNC_GAME_LIST,
  UPDATE_GAME_STATUS
} from './types';

const initialState = {
  gameList: [],
  total: 0,
};

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GAME_LIST:
    case SYNC_GAME_LIST:
      return {
        ...state,
        gameList: action.payload,
        total: action.total,
      };

      
        
            // case UPDATE_GAME_STATUS:
            //     return {
            //         ...state,
            //         gameList: state.gameList.map((gameList) =>
            //         gameList._id === action.payload._id ? { ...gameList, status: action.payload.status } : game
            //         ),
            //         total: action.total,
            //     };


            case UPDATE_GAME_STATUS:
      return {
        ...state,
        gameList: state.gameList ? state.gameList.map((gameList) => {
          if (gameList._id === action.payload._id)
            return {
              ...gameList,
              status: action.payload.status,
            };
          else return gameList;
        }):[],
     
      };
            
    default:
      return state;
  }
};

export default gameReducer;
