import React, { useEffect, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { getGameList, syncGameList, updateGameStatus } from '../../store/game/action';
import { Link } from "react-router-dom";
import Male from "../../assets/images/male.png";
import Pagination from "../../pages/Pagination";
import { convertThousandNumbers } from "../../util/Helper";


import EdiText from "react-editext";

const GameList = (props) => {

    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [status, setStatus] = useState("");
    const [order, setOrder] = useState ("");
    const [cancelFn, setCancelFn] = useState(() => () => { });
    const startIndex = (activePage - 1) * rowsPerPage;

    const fetchGameList = async () => {

        const result = await props.getGameList(status, activePage, rowsPerPage, order);
        setData(result || []); // Ensure result is an array

    };

    useEffect(() => {
        fetchGameList();
    }, [status, activePage, rowsPerPage, order]);

    const syncGameList_ = async () => {

        await props.syncGameList(data);
        fetchGameList();

    };

    useEffect(() => () => cancelFn(), [cancelFn]);

    const { gameList, total } = useSelector((state) => state.gameList);

    useEffect(() => {

        if (gameList.length) {
            setData(gameList);
        }
    }, [gameList]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        handlePageChange(1);
        setRowsPerPage(value);

    };

    const updateGameStatus = async (gameId, status , order) => {

        await props.updateGameStatus(gameId, status, order);
        setData((prevData) =>
            prevData.map((game) =>
                game._id === gameId ? { ...game, status, order } : game
            )
        );
        fetchGameList();
    };

    return (
        <>
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className="mb-3">Game List</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Game List
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="card" id="card">
                        <div className="card-header pb-0">
                            <div className="row my-3">
                                <div className="mb-2">

                                    <button
                                        type="button"
                                        className="btn btn-info"
                                        onClick={syncGameList_}
                                        style={{ marginRight: 5 }}
                                    >
                                        Sync Game List
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body card-overflow pt-0">
                            <table className="table table-striped mt-5">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Game Image</th>
                                        <th>Game Name</th>
                                        <th>Game ID</th>
                                        <th>Game Status</th>
                                        <th>Game Order</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? (
                                        data.map((data, index) => {
                                            return (
                                                <tr key={startIndex + index}>
                                                    <td>{startIndex + index + 1}</td>
                                                    <td>
                                                        <img
                                                            height="80px"
                                                            width="80px"
                                                            alt="app"
                                                            src={data.previewUrl ? data.previewUrl : Male} // Use data.previewUrl directly
                                                            style={{
                                                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                                border: "2px solid #fff",
                                                                borderRadius: 10,
                                                                float: "left",
                                                                objectFit: "cover",
                                                            }}
                                                        />

                                                    </td>
                                                    <td>{data.name}</td>
                                                    <td>{data.providerGameId}</td>
                                                    <td>
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={data.status}
                                                                onChange={() => updateGameStatus(data._id, !data.status, data.order)}
                                                            />
                                                            <span className="slider">
                                                                <p
                                                                    style={{
                                                                        fontSize: 12,
                                                                        marginLeft: `${data.status ? "5px" : "35px"
                                                                            }`,
                                                                        color: "#000",
                                                                        marginTop: "6px",
                                                                    }}
                                                                >
                                                                    {data.status ? "On" : "Off"}
                                                                </p>
                                                            </span>
                                                        </label>
                                                    </td>
                                                   
                                                    <td>
                                                <span className="d-flex" style={{ alignItems: "baseline", width: '50px' }}>
                                                    <EdiText
                                                        type="text"
                                                        value={convertThousandNumbers(data.order ? data.order : "")}
                                                        onSave={(val) => updateGameStatus(data._id, data.status, val)}                        
                                                        className="editClass"
                                                        style={{ width: 'auto', maxWidth: '50px',}}
                                                       
                                                        inputProps={{
                                                            className: 'editClass__input',
                                                            inputMode: "numeric",
                                                            onKeyPress: (event) => {
                                                              if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                              }
                                                            }
                                                          }}                                                   
                                                    />
                                                </span>

                                                    </td>


                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="10" align="center">
                                                Nothing to show!!
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <Pagination
                                activePage={parseInt(activePage)}
                                rowsPerPage={rowsPerPage}
                                userTotal={total}
                                handleRowsPerPage={handleRowsPerPage}
                                handlePageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );


};

export default connect(null, { getGameList, syncGameList, updateGameStatus })(GameList);

