import React, { useState, useEffect } from "react";

//react-router
import { connect, useSelector } from "react-redux";

import { Link, useHistory, useLocation  } from "react-router-dom";

//action

import { getMultiLiveLiveRoomByLiveRoomId, getLiveRoomDetail } from "../store/liveRoom/action";
import { pkTypeList } from "../util/Helper";

//dayjs
import dayjs from "dayjs";



//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Male from "../assets/images/male.png";
//MUI
import { Tooltip } from "@material-ui/core";

//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const pkList = pkTypeList();

const LiveRoomDetail = (props) => {
  const history_ = useHistory();
  const [start, setStart] = useState(1);
  const [limit, seteLimit] = useState(10);
  const [terminatedUser, setTerminatedUser] = useState(null);

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const liveRoomIdPre = props.match.params.liveRoomId;
  const liveRoomUserNamePre = props.match.params.userId;


  const { multiLiveRoom, totalMultiLiveRoom } = useSelector(
    (state) => state.multiLiveRoom
  );
  const { liveRoom } = useSelector(
    (state) => state.multiLiveRoom
  );

  useEffect(() => {
    props.getMultiLiveLiveRoomByLiveRoomId(liveRoomIdPre, start, limit)
    props.getLiveRoomDetail(liveRoomIdPre)
    // getUserInfo(liveRoomUserNamePre);
}, [liveRoomIdPre, start, limit]);

  useEffect(() => {
    setData(multiLiveRoom);
  }, [multiLiveRoom]);


  // Function to get PK type label based on mode value
    const getPkTypeLabel = (mode) => {
      const pkType = pkList.find(item => item.value === mode);
      return pkType ? pkType.label : null;
    }

    const handleParticipantList = (userId, liveRoomId, multiLiveRoomId, userName, pkMode) => {
      // history_.push("/admin/liveRoom/"+ userId + "/" + liveRoomId + "/" + multiLiveRoomId);
      history_.push({
        pathname: "/admin/liveRoom/"+ userId + "/" + liveRoomId + "/" + multiLiveRoomId,
        state: {
          multiLiveRoomId: multiLiveRoomId,
          liveRoomId: liveRoomId,
          liveRoomUserNamePre: userId,
          userName: userName,
          pkMode: pkMode,
        }
      });
    }

    const handleUserInfo = (userId) => {
      history_.push("/admin/user/detail/"+ userId);
    };

  return (
    <>

      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3">{liveRoom?.user?.name}'s Live Room</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/user" className="text-danger">
                    User
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/admin/user/history/${liveRoomUserNamePre}`} className="text-danger">
                    History
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    Multi-Live
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">

            <div class="card-body card-overflow pt-0">

                  <div className="d-flex justify-content-between mt-5">
                    <h4 className="text-primary">Live Room Information</h4>
                  </div>
                  <table class="table table-striped mt-3">
                    <thead>
                      <tr>
                        <th>Created Date</th>
                        <th>Ended Date</th>
                        <th>Duration</th>
                        <th>Room Title</th>
                        <th>Joined Viewer</th>
                        <th>New Follower</th>
                        <th>Earned Gift</th>
                        <th>Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveRoom != null ? (
                            <tr>
                              <td>
                                {dayjs(liveRoom.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                              </td>
                              <td>
                                {dayjs(liveRoom.endAt).format('YYYY-MM-DD HH:mm:ss')}
                              </td>
                              <td>{dayjs().startOf('day').add(liveRoom.duration, 'second').format('HH:mm:ss')}</td>
                              <td>{liveRoom.title == "" ? "-" : liveRoom.title != null ? liveRoom.title :"-"}</td>
                              <td>{liveRoom.viewerCount}</td>
                              <td>{liveRoom.increaseFollowerCount}</td>
                              <td>{liveRoom.giftCount}</td>
                              <td>{liveRoom.commentCount}</td>
                            </tr>

                      ) : (
                        <tr>
                          <td colSpan="8" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                
            </div>

            <div class="card-body card-overflow pt-0">

                  <div className="d-flex justify-content-between mt-3">
                    <h4 className="text-primary">Multi-Live Room Information</h4>
                  </div>
                  <table class="table table-striped mt-3">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>Duration</th>
                        <th>PK Mode</th>
                        <th>Joined Participant</th>
                        <th>PK Session</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        data.map((data, index) => {
                          return (
                            <React.Fragment key={index} >
                            <tr onClick={() => setSelectedRow(selectedRow === index ? null : index)}>
                              <td>{index + 1}</td>
                              <td>
                                {dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                              </td>
                              <td>{dayjs().startOf('day').add(data.duration, 'second').format('HH:mm:ss')}</td>
                              <td className="text-center text-primary">
                                {getPkTypeLabel(data.mode)}
                              </td>
                              <td className="text-center text-primary">
                                {data.participantList.length}
                              </td>
                              <td>
                                <Tooltip title="Info">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-info"
                                    onClick={() => handleParticipantList(liveRoomUserNamePre, liveRoomIdPre, data._id, liveRoom?.user?.name, data.mode)}
                                    disabled={ data.additionalInfo?.hasPkSession == true ? false : true}
                                  >
                                    <i className="fas fa-info-circle fa-lg"></i>
                                  </button>
                                </Tooltip>
                              </td>
                              <td>
                              {selectedRow === index ? <ExpandLessIcon style={{ fontSize: '1.0rem' }}/> : <ExpandMoreIcon style={{ fontSize: '1.0rem' }} />}
                              </td>
                            </tr>


                            {selectedRow === index && (
                              <>
                                <tr className="custom-table2">

                                  <td colSpan="7">
                                    <h4 className="text-primary" style={{paddingLeft:"15px"}}>Joined Participant List</h4>
                                    <table style={{width:"100%"}}>
                                      <thead>
                                      <tr>
                                        <th>No</th>
                                        <th>Image</th>
                                        <th>Name (Username)</th>
                                        <th>Join at</th>
                                        <th>Leave at</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {data.participantList
                                      .map((participant, participantIndex) => (
                                        <tr>
                                          <td>{participantIndex + 1}</td>
                                          <td onClick={() => handleUserInfo(participant.userInfo._id)} className="pointer-cursor">
                                            <img
                                              height="50px"
                                              width="50px"
                                              alt="app"
                                              src={participant.userInfo.image ? participant.userInfo.image : Male}
                                              style={{
                                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                border: "2px solid #fff",
                                                borderRadius: 10,
                                                float: "left",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </td>
                                          <td onClick={() => handleUserInfo(participant.userInfo._id)} className="pointer-cursor">
                                            {participant.userInfo.name}
                                          </td>
                                          <td>{dayjs(participant.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                          <td>{dayjs(participant.leaveAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                          
                                        </tr>
                                      ))}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </>
                            )}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="7" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getMultiLiveLiveRoomByLiveRoomId, getLiveRoomDetail })(
  LiveRoomDetail
);
