import axios from "axios";
import { Toast } from "../../util/Toast";

// actions/gameActions.js
import {
    GET_GAME_LIST,
    SYNC_GAME_LIST,
    UPDATE_GAME_STATUS,
  } from './types';
 
  const API_BASE_URL = 'http://localhost:3002'; // Ensure this matches your API base URL


export const getGameList = (status,start,limit) => async (dispatch) => {
    
    let url = `game?status=${status}&start=${start}&limit=${limit}`

    axios.get(url)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: GET_GAME_LIST,
          payload: res.data?.data ?? [],
          total:res.data.total
        });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const syncGameList = (data) => async (dispatch) => {
    axios.post(`game/sync-game-list`,data)
        .then((res) => {
            if (res.status) {
                dispatch({
                    type: SYNC_GAME_LIST,
                    payload: res.data?.data ?? [],
                    total: res.data.total,
                });
                Toast("success", "Game list synchronized successfully!");
            } else {
                Toast("error", res.message);
            }
        })
        .catch((error) => Toast("error", error.message));
};

export const updateGameStatus = (gameId,status,order) => (dispatch) => {
    axios
      .put(`game/${gameId}`, { status , order })
      
      .then((res) => {
        if (res.status) {
          dispatch({ type: UPDATE_GAME_STATUS, payload: res.data?.data ?? []});
          Toast("success", "Game status updated successfully!"); // Toast success message
        } else {
          Toast("error", res.data.message); // Toast error message if API call fails
        }
      })
      .catch((error) => Toast("error", error.message)); // Handle error if promise is rejected
  };
  