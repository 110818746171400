import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// antd
import "antd/dist/antd.css";

// js
import "../assets/js/main.min.js";

//router
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

// component
import Navbar from "../component/navbar/Navbar";
import Topnav from "../component/navbar/Topnav";
import BannerTable from "../component/table/Banner";
import CoinPlanTable from "../component/table/CoinPlan";
import PurchaseCoinPlanHistoryTable from "../component/table/PurchaseCoinPlanHistory";
import VIPPlanTable from "../component/table/VIPPlan";
import PurchaseVIPPlanTable from "../component/table/PurchaseVipPlanHistory";
import GiftCategoryTable from "../component/table/GiftCategory";
import GiftTable from "../component/table/Gift";
import SongTable from "../component/table/Song";
import SongDialog from "../component/dialog/Song";
import GiftDialog from "../component/dialog/Gift/Add";
import HashtagTable from "../component/table/Hashtag";
import LevelTable from "../component/table/Level";
import UserTable from "../component/table/User";
import PostTable from "../component/table/Post";
import AgencyTable from "../component/table/Agency";
import VideoTable from "../component/table/Video";
import UserDetail from "./UserDetail";
import UserHistory from "./UserHistory";
import PostDetail from "./PostDetail";
import VideoDetail from "./VideoDetail";
import Dashboard from "./Dashboard";
import Setting from "./Settings";
import Advertisement from "../component/table/Advertisement";
import PendingComplainTable from "../component/table/PendingComplain";
import SolvedComplainTable from "../component/table/SolvedComplain";
import PendingRedeemTable from "../component/table/PendingRedeem";
import AcceptedRedeemTable from "../component/table/AcceptedRedeem";
import DeclineRedeemTable from "../component/table/DeclineRedeem";
import ReportedUserTable from "../component/table/ReportedUser";
import AnnouncementTable from "../component/table/Announcement";
import StickerTable from "../component/table/Sticker";
import Wallet from "./Wallet";
import Report from "./Report";
import Page404 from "./Page404.js";
import Permission from "./Permission.js";

//loader
import Spinner from "./Spinner";
import Profile from "./Profile.js";
import StreamLiveSummary from "../component/table/StreamLiveSummary.js";
import LiveRoomDetail from "./LiveRoomDetail.js";
import PKDetail from "./PKDetail.js";
import Ranking from "../component/table/Ranking.js";
import Game from "../component/table/Game.js";
import { checkPermission } from "../util/Helper.js";

const Admin = () => {
  const location = useRouteMatch();
  const history = useHistory();
  const { role, permissions } = useSelector((state) => state.admin?.admin);

  useEffect(() => {
    if (history.location.pathname === "/admin") {
      history.push("/admin/dashboard");
    } // eslint-disable-next-line
  }, []);

  return (
    <>
      <div class="page-container">
        <Navbar />
        <div class="page-content">
          <Topnav />
          <div class="main-wrapper">
            <Switch>
              {checkPermission("dashboard.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/dashboard`}
                  exact
                  component={Dashboard}
                />
              )}
              {checkPermission("profile.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/profile`}
                  exact
                  component={Profile}
                />
              )}
              {checkPermission("banner.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/banner`}
                  exact
                  component={BannerTable}
                />
              )}
              {checkPermission(["rCoinReceiverRanking.read", "giftSenderRanking.read"], permissions, role?.name) && (
                <Route
                  path={`${location.path}/Ranking`}
                  exact
                  component={Ranking}
                />
              )}
              {checkPermission("coinPlan.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/coinplan`}
                  exact
                  component={CoinPlanTable}
                />
              )}
              {checkPermission("coinPlanTransaction.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/coinplan/history`}
                  exact
                  component={PurchaseCoinPlanHistoryTable}
                />
              )}
              {checkPermission("vipPlan.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/vipplan`}
                  exact
                  component={VIPPlanTable}
                />
              )}
              {checkPermission("game.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/Game`}
                  exact
                  component={Game}
                />
              )}
              {checkPermission("vipPlanTransaction.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/vipplan/history`}
                  exact
                  component={PurchaseVIPPlanTable}
                />
              )}
              {checkPermission("giftCategory.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/giftCategory`}
                  exact
                  component={GiftCategoryTable}
                />
              )}

              {checkPermission("gift.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/giftCategory/gift`}
                  exact
                  component={GiftTable}
                />
              )}
              {checkPermission("gift.create", permissions, role?.name) && (
                <Route
                  path={`${location.path}/giftCategory/gift/dialog`}
                  exact
                  component={GiftDialog}
                />
              )}
              {checkPermission("gift.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/gift`}
                  exact
                  component={GiftTable}
                />
              )}
              {checkPermission("gift.create", permissions, role?.name) && (
                <Route
                  path={`${location.path}/gift/dialog`}
                  exact
                  component={GiftDialog}
                />
              )}
              {checkPermission("song.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/song`}
                  exact
                  component={SongTable}
                />
              )}
              {checkPermission("song.create", permissions, role?.name) && (
                <Route
                  path={`${location.path}/song/dialog`}
                  exact
                  component={SongDialog}
                />
              )}
              {/* <Route
                path={`${location.path}/hashtag`}
                exact
                component={HashtagTable}
              /> */}
              {checkPermission("level.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/level`}
                  exact
                  component={LevelTable}
                />
              )}
              {checkPermission("user.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/user`}
                  exact
                  component={UserTable}
                />
              )}
              {checkPermission("agency.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/agency`}
                  exact
                  component={AgencyTable}
                />
              )}
              {checkPermission("user.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/user/detail/:userId`}
                  exact
                  component={UserDetail}
                />
              )}
              {checkPermission("user.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/user/history/:userId`}
                  exact
                  component={UserHistory}
                />
              )}
              {checkPermission("user.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/liveRoom/:userId/:liveRoomId`}
                  exact
                  component={LiveRoomDetail}
                />
              )}
              {checkPermission("user.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/liveRoom/:userId/:liveRoomId/:multiLiveRoomId`}
                  exact
                  component={PKDetail}
                />
              )}
              {checkPermission("post.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/post`}
                  exact
                  component={PostTable}
                />
              )}
              {checkPermission("post.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/post/detail`}
                  exact
                  component={PostDetail}
                />
              )}
              {checkPermission("video.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/video`}
                  exact
                  component={VideoTable}
                />
              )}
              {checkPermission("setting.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/setting`}
                  exact
                  component={Setting}
                />
              )}
              {checkPermission("video.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/video/detail`}
                  exact
                  component={VideoDetail}
                />
              )}
              {checkPermission("reportUser.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/reportedUser`}
                  exact
                  component={ReportedUserTable}
                />
              )}
              {checkPermission("announcement.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/announcement`}
                  exact
                  component={AnnouncementTable}
                />
              )}
              {/* <Route
                path={`${location.path}/advertisement`}
                exact
                component={Advertisement}
              /> */}
              {checkPermission("complain.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/pendingComplain`}
                  exact
                  component={PendingComplainTable}
                />
              )}
              {checkPermission("complain.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/solvedComplain`}
                  exact
                  component={SolvedComplainTable}
                />
              )}
              {/* <Route
                path={`${location.path}/pendingRedeem`}
                exact
                component={PendingRedeemTable}
              />
              <Route
                path={`${location.path}/acceptedRedeem`}
                exact
                component={AcceptedRedeemTable}
              />
              <Route
                path={`${location.path}/declineRedeem`}
                exact
                component={DeclineRedeemTable}
              /> */}
              {/* <Route
                path={`${location.path}/sticker`}
                exact
                component={StickerTable}
              /> */}
              {checkPermission("wallet.topUp", permissions, role?.name) && (
                <Route
                  path={`${location.path}/wallet`}
                  exact
                  component={Wallet}
                />
              )}
              {checkPermission(["rCoinTransaction.read", "liveRoomHistory.read"], permissions, role?.name) && (
                <Route
                  path={`${location.path}/report`}
                  exact
                  component={Report}
                />
              )}
              {checkPermission("liveSummary.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/streamerLiveSummary`}
                  exact
                  component={StreamLiveSummary}
                />
              )}
              {checkPermission("rolePermission.read", permissions, role?.name) && (
                <Route
                  path={`${location.path}/permission`}
                  exact
                  component={Permission}
                />
              )}
              <Route component={Page404} />
            </Switch>
            <Spinner />
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
