import {
    GET_AGENCY,
    CREATE_NEW_AGENCY,
    UPDATE_AGENCY,
    CLOSE_AGENCY_DIALOG,
    DELETE_AGENCY,
    GET_TOTAL_AGENCY,
    OPEN_AGENCY_DIALOG,
} from "./types";
const initialState = {
    agency: [],
    dialog: false,
    totalUser: 0,
    dialogData: null,
};
const agencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_NEW_AGENCY:
            const data = [action.payload,...state.agency]
            return {
              ...state,
              agency: data,
            };
        case GET_AGENCY:
            return {
               ...state,
               agency: action.payload.agency,
               totalUser: action.payload.total,
            };
        case CLOSE_AGENCY_DIALOG:
            return {
              ...state,
              dialog: false,
              dialogData: null,
            };
        case DELETE_AGENCY:
             return {
              ...state,
              agency: state.agency.filter(
              (agency) => agency._id !== action.payload
        ),
      };  
        case OPEN_AGENCY_DIALOG:
            return {
              ...state,
              dialog: true,
              dialogData: action.payload || null,
            }; 
        case UPDATE_AGENCY:
             return {
              ...state,
              agency: state.agency.map((agency) => {
              if (agency._id === action.payload.id)
                return action.payload.data;
              else return agency;
        }),
      };  

      default:
        return state;        
    }
    
};

export default agencyReducer;