import React, { useEffect, useState } from "react";
import axios from "axios";

//redux
import { useSelector, connect } from "react-redux";

//routing
import { Link } from "react-router-dom";

//sweet alert
import { warning, permissionError } from "../util/Alert";
import { Toast } from "../util/Toast";

const getPermissions = async () => {
  return axios
    .get(`permissions`)
    .then((res) => {
      if (res.data.status) {
        return res.data.data;
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

const getRolePermissions = async () => {
  return axios
    .get(`role-permissions`)
    .then((res) => {
      if (res.data.status) {
        return res.data.data;
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

const updateRolePermission = async (data) => {
  return axios
    .post(`role-permissions`, data)
    .then((res) => {
      if (res.data.status) {
        return res;
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

const Switch = (props) => {
  const { checked, onChange } = props;
  const [isChecked, setIsChecked] = useState(checked ?? false);

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={(e) => {
          onChange?.(e);
          setIsChecked(e.currentTarget.checked);
        }}
      />
      <span className="slider">
        <p style={{ fontSize: 12, marginLeft: isChecked ? "5px" : "35px", color: "#000", marginTop: "6px" }}>
          {isChecked ? "Yes" : "No"}
        </p>
      </span>
    </label>
  );
};

const Permission = (props) => {
  const hasPermission = useSelector((state) => state.admin.admin.flag);
  const [permissions, setPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);

  const handleUpdate = (e) => {
    e?.preventDefault();

    const confirmation = warning();

    confirmation
      .then(async (isConfirm) => {
        if (isConfirm) {
          if (!hasPermission) return permissionError();
          const data = rolePermissions.map((rp) => ({ id: rp._id, permissions: rp.permissions.map((p) => p._id) }));
          await updateRolePermission({
            roles: data,
          }).then((res) => {
            if (res.data.status) {
              setRolePermissions(res.data.data);
            }
          });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    (async () => {
      const [permissions, rolePermissions] = await Promise.all([getPermissions(), getRolePermissions()]);
      setRolePermissions(rolePermissions);
      const tempPermissions = [];
      permissions.forEach((permission) => {
        let [subject, action] = permission.name.split(".");

        if (
          (action === "read" || permission.name === "wallet.topUp") &&
          subject !== "admin" &&
          !["giftSenderRanking.read", "liveRoomHistory.read"].includes(permission.name)
        ) {
          let tempName = permission.name;
          switch (permission.name) {
            case "rCoinReceiverRanking.read":
              tempName = "ranking.read";
              subject = "ranking";
              break;
            case "rCoinTransaction.read":
              tempName = "report.read";
              subject = "report";
              break;
            case "rolePermission.read":
              tempName = "permission.read";
              subject = "permission";
              break;
            default:
              break;
          }
          tempPermissions.push({
            ...permission,
            name: tempName,
            subject,
            action,
            displayName: `${subject.replace(/([A-Z])/g, " $1")} - ${action}`,
          });
        }
      });
      setPermissions(tempPermissions);
    })();
  }, []);

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3">Permission</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Permission
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleUpdate}
                    id="bannerDialog"
                  >
                    <span className="icon_margin">Submit</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th></th>
                    {rolePermissions.map((role) => (
                      <th
                        key={role._id}
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {role.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {permissions.map((permission, i) => (
                    <tr key={permission._id ?? i}>
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {permission.displayName}
                      </td>
                      {rolePermissions.map((rp, j) => {
                        const isChecked = !!rp?.permissions?.some((p) => p._id === permission._id);

                        return (
                          <td key={rp._id ?? j}>
                            <Switch
                              checked={isChecked}
                              onChange={(e) => {
                                setRolePermissions((prev) => {
                                  const rpIndex = j;
                                  const pIndex = prev[rpIndex].permissions?.findIndex((p) => p._id === permission._id);
                                  const isChecked = pIndex >= 0;

                                  if (isChecked) {
                                    prev[rpIndex].permissions.splice(pIndex, 1);
                                  } else {
                                    prev[rpIndex].permissions.push(permission);
                                  }
                                  return prev;
                                });
                              }}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {})(Permission);
