import React from "react";
import { convertThousandNumbers } from "../../../util/Helper";
import dayjs from "dayjs";

const PurchaseCoinPlan = (props) => {
  return (
    <table class="table table-striped mt-5">
      <thead>
        <tr>
          <th>No.</th>
          <th>User Name</th>
          <th>Diamond</th>
          <th>Dollar</th>
          {/* <th>Rupee</th> */}
          <th>Payment Gateway</th>
          <th>Purchase Date</th>
        </tr>
      </thead>
      <tbody>
        {props.data?.length > 0 ? (
          props.data.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.name}</td>
                <td className="text-primary">{convertThousandNumbers(data.diamond)}</td>
                <td className="text-info">{convertThousandNumbers(data.dollar)}</td>
                {/* <td className="text-success">{convertThousandNumbers(data.rupee)}</td> */}
                <td className="text-danger">{data.paymentGateway}</td>
                <td>{dayjs(data.purchaseDate).format("DD/MM/YYYY hh:mm:ss A")}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="7" align="center">
              Nothing to show!!
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default PurchaseCoinPlan;
