import dayjs from "dayjs";
import { CLOSE_LIVE_DETAIL_DIALOG, EXPORT_LIVE_SUMMARY_REPORT, GET_STREAMER_LIVE_SUMMARY, GET_STREAMER_LIVE_SUMMARY_DETAIL } from "./types"
import { exportData } from "../../util/Helper";

const initialState = {
  streamer: [],
  totalStreamer: 0,
  additionalInfo: {},
  dialog: false,
  dialogData: null,
};

const streamerLiveSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STREAMER_LIVE_SUMMARY:
      return {
        ...state,
        streamer: action.payload,
        totalStreamer: action.total,
        additionalInfo: action.additionalInfo
      };
    case GET_STREAMER_LIVE_SUMMARY_DETAIL:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_LIVE_DETAIL_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    case EXPORT_LIVE_SUMMARY_REPORT:
      const today = new Date();
      let date = dayjs(today).format("DDMMYY-HHmmss");
      const csvData = action.payload.csv
      exportData(csvData, "LiveSummary-" + action.payload.month + "-Report" + date + '.csv', 'text/csv;charset=utf-8;');
    default:
      return state;
  }
};

export default streamerLiveSummaryReducer;
