import {
  GET_REDEEM, 
  ACCEPT_REDEEM,
  OPEN_ACCEPT_DIALOG,
  CLOSE_ACCEPT_DIALOG,
  OPEN_DECLINE_DIALOG,
  CLOSE_DECLINE_DIALOG
} from "./types";

const initialState = {
  redeem: [],
};

const redeemReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REDEEM:
      return {
        ...state,
        redeem: action.payload,
      };

    case ACCEPT_REDEEM:
      return {
        ...state,
        redeem: state.redeem.filter(
          (redeem) => redeem._id !== action.payload._id
        ),
      };
    case OPEN_ACCEPT_DIALOG:
      return {
        ...state,
        dialogAccept: true,
        dialogData: action.redeem || null,
      };
    case CLOSE_ACCEPT_DIALOG:
      return {
        ...state,
        dialogAccept: false,
        dialogData: null,
      };

    case OPEN_DECLINE_DIALOG:
      return {
        ...state,
        dialogDecline: true,
        dialogData: action.redeem || null,
      };
    case CLOSE_DECLINE_DIALOG:
      return {
        ...state,
        dialogDecline: false,
        dialogData: null,
      };
    default:
      return state;
  }
};

export default redeemReducer;
