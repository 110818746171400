import axios from "axios";
import { Toast } from "../../util/Toast";
import { CHECK_USERNAME, WALLET_TOPUP, GET_REPORT_HISTORY, EXPORT_REPORT,PURGE_COIN } from "./types";
import { formatDateTime } from "../../util/Helper";
import dayjs from "dayjs";

export const topupWallet = (data) => (dispatch) => {
    axios
        .post(`user/topupRcoinDiamond`, data)
        .then((res) => {
            if (res.data.status) {
                Toast("success", "Wallet top up successfully!");
                dispatch({ type: WALLET_TOPUP, payload: res.data });
            } else {
                Toast("error", res.data.message);
            }
        })
        .catch((error) => Toast("error", error.message));
};

export const checkUsername = (data) => (dispatch) => {
    axios
        .post(`user/topupRcoinDiamond/checkUsername`, data)
        .then((res) => {
            if (res.data.status) {
                dispatch({
                    type: CHECK_USERNAME,
                    payload: { data: res.data },
                });
            } else {
                dispatch({
                    type: CHECK_USERNAME,
                    payload: { usernameError: res.data.message },
                });
            }
        })
        .catch((error) => Toast("error", error.message));
};

export const reportHistory = (data) => (dispatch) => {
    data.startDate = formatDateTime(dayjs(data.startDate).format("YYYY-MM-DD 00:00:00"))
    data.endDate = formatDateTime(data.endDate)

    axios
      .post(`all-history`, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: GET_REPORT_HISTORY, payload: res.data });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

  export const exportReport = (data) => (dispatch) => {
    axios
      .post(`export-all-history`, data)
      .then((res) => {
        if (res.status==200) {
          dispatch({ type: EXPORT_REPORT, payload: {csv: res.data, type: data.type},  });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

  export const purgeCoin = (data) => (dispatch) => {
    axios
      .post(`purge-rcoin`, data)
      .then((res) => {
        if (res.status==200) {
          dispatch({ type: PURGE_COIN, payload: res.data,  });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };